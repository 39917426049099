<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/product'"
        [loading]="loading"
        [page]="page"
        [title]="'Articles'"
        [values]="product"
        (searchItemEmitter)="searchProduct()"
        (sortEventEmitter)="sortProduct($event)"
        (loadItemPageEmitter)="loadProductPage($event)"
        (launchBatchEmitter)="launchBatch()"
        [displayImport]="false"
        [displayLaunchImport]="true"
        [detailPage]="true"
        [sortData]="true">
</app-list-tab-view>
