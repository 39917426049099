import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LazyTableComponent} from './lazy/lazy-table/lazy-table.component';
import {LazyTableCriteriaComponent} from './lazy/lazy-table-criteria/lazy-table-criteria.component';
import {SharedModule} from 'src/app/shared/shared.module';

@NgModule({
    declarations: [LazyTableComponent, LazyTableCriteriaComponent],
    imports: [
        CommonModule,
        SharedModule,
    ],
    exports: [LazyTableComponent, LazyTableCriteriaComponent]
})
export class AppTableModule {
}
