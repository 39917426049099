import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    collection: any[];

    constructor(
        private http: HttpClient
    ) {
    }

    create(url: string, payload: any): Observable<any> {
        return this.http.post(url, payload);
    }

    read(url: string): Observable<any> {
        return this.http.get(url);
    }

    readWithParams(url: string, params: HttpParams): Observable<any> {
        return this.http.get(url, {params});
    }

    update(url: string, payload: any): Observable<any> {
        return this.http.patch(url, payload);
    }

    delete(url: string): Observable<any> {
        return this.http.delete(url);
    }

    import(url: string, payload: any) {
        return this.http.post(url, payload);
    }

    deleteWithParams(url: string, params: HttpParams): Observable<any> {
        return this.http.delete(url, {params});
    }
}
