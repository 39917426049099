<h1 class="title">{{title}}</h1>

<p-tabView>
    <p-tabPanel>
        <ng-template pTemplate="header" class="tab-header">Détails</ng-template>
        <div class="tab-content-layout">
            <app-detail-data-view [loading]="loading"
                                  [value]="value">
            </app-detail-data-view>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Ligne" *ngIf="displayRow">
    </p-tabPanel>
</p-tabView>
<div class="p-col-5 p-md-4 p-lg-2">
    <button pButton
            type="button"
            icon="pi pi-undo"
            label="Retour"
            class="ui-button-raised w-100"
            [disabled]="loading"
            (click)="redirection()">
    </button>
</div>
