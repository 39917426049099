import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MenuModule} from './menu/menu.module';
import {AppTableModule} from './table/app-table.module';
import {DetailModule} from "./detail/detail.module";
import {ImportComponent} from './import/import.component';

@NgModule({
    declarations: [ImportComponent],
    imports: [
        CommonModule,
        SharedModule,
        MenuModule,
        AppTableModule,
        DetailModule
    ],
    exports: [
        MenuModule,
        AppTableModule,
        DetailModule,
        ImportComponent,
    ]
})
export class ComponentsModule {
}
