// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // todo: check url
    apiUrl: '/api/v1.0',
    resourceUri: {
        config: '/config',
        users: '/internal/user',
        pricing: '/internal/pricing',
        contact: '/internal/contact',
        orderModel: '/internal/order-model',
        reference: '/internal/referencing',
        import: '/internal/import-status',
        launchBatch: '/internal/batch/trigger',
        customer: '/internal/customers',
        login: '/internal/login',
        salesHistory: '/internal/sales-history',
        updatedOrder: '/internal/updated-orders',
        order: '/internal/orders',
        product: '/internal/products',
        orderLine: '/internal/order-lines',
        spitBackground: '/internal/spit-background',
        execution: '/internal/batch',
        executionDetail: '/internal/import-status'
    },
    adfs: {
        tenant: '...',
        clientId: '...'
    },
    technicalLogin: '...',
    technicalPassword: '...'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
