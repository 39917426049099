import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuTopComponent } from './menu-top/menu-top.component';
import { MenuSideLeftComponent } from './menu-side/menu-side-left/menu-side-left.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MenuMainComponent } from './menu-main/menu-main.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MenuSideRightComponent } from './menu-side/menu-side-right/menu-side-right.component';

@NgModule({
  declarations: [MenuTopComponent, MenuSideLeftComponent, MenuMainComponent, MenuSideRightComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    TieredMenuModule,
    FlexLayoutModule,
    SharedModule
  ],
  exports: [MenuMainComponent]
})
export class MenuModule { }
