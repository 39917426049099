import {Role} from "../../@entities/role";
import {Injectable} from "@angular/core";
import {MsAdalAngular6Service} from "microsoft-adal-angular6";

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private adminGroups: string[] = JSON.parse(sessionStorage.getItem("adminGroups"));
    private importGroups: string[] = JSON.parse(sessionStorage.getItem("importGroups"));

    role: Role;
    user: adal.User;

    constructor(private adalSvc: MsAdalAngular6Service) {
        let currentRole = JSON.parse(localStorage.getItem('role'));
        if (currentRole === null) {
            this.loadRole();
        }
        this.user = this.adalSvc.userInfo;
    }

    public isAuthenticated(): boolean {
        return this.adalSvc.isAuthenticated;
    }

    public login() {
        this.adalSvc.login();
    }

    private loadRole() {
        let role: Role = this.buildUserRole();
        // store user role
        sessionStorage.setItem("role", JSON.stringify(role));
        this.role = role;
    }

    private buildUserRole(): Role {
        let userGroups: string[] = this.adalSvc.userInfo?.profile?.roles;
        let role = Role.USER;
        userGroups?.forEach(group => {
            if (this.adminGroups.indexOf(group) !== -1) {
                role = Role.ADMIN;
            } else if (this.importGroups.indexOf(group) !== -1) {
                role = Role.IMPORT;
            }
        });

        return role;
    }
}
