import {Component, OnInit} from '@angular/core';
import {MenuSideService} from '../menu-side/services/menu-side.service';

@Component({
    selector: 'app-menu-top',
    templateUrl: './menu-top.component.html',
    styleUrls: [
        './menu-top.component.scss'
    ]
})
export class MenuTopComponent implements OnInit {


    constructor(public sideBarService: MenuSideService) {
    }

    ngOnInit() {
    }

    onDisplaySideMenu() {
        this.sideBarService.displaySideBarLeft();
    }

}
