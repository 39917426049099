<p-dataView [value]="rowsToPrint" [loading]="loading">
    <p-header>
        <div class="ui-g">
            <div class="ui-g-6">
                <p-multiSelect [options]="rows"
                               optionLabel="label"
                               [(ngModel)]="selectedRows">
                    <ng-template let-selectedRows pTemplate="selectedItems">
                        <div class="ui-multiselected-item-token ui-corner-all" *ngIf="selectedRows && selectedRows.length < 4">
                            <span *ngFor="let selectedRow of selectedRows; last as isLast" style="vertical-align:middle">
                                {{selectedRow.label}}<span *ngIf="!isLast">, </span>
                            </span>
                        </div>
                        <span *ngIf="!selectedRows || selectedRows.length === 0" class="ui-multiselected-empty-token ui-corner-all">Propriété</span>
                        <span *ngIf="selectedRows && selectedRows.length >= 4" class="ui-multiselected-item-token ui-corner-all">{{selectedRows.length}} propriétés choisies</span>
                    </ng-template>
                    <ng-template let-row pTemplate="item">
                        <div style="display:inline-block;vertical-align:middle">{{row.label}}</div>
                    </ng-template>
                </p-multiSelect>
            </div>
            <div class="ui-g-3">
                <button *ngIf="isSearched"
                        pButton
                        type="button"
                        icon="pi pi-times"
                        label="Annuler"
                        class="ui-button-raised w-100 cancel-button"
                        [disabled]="loading"
                        (click)="cancelSearch()">
                </button>
            </div>
            <div class="ui-g-3">
                <button pButton
                        type="button"
                        icon="pi pi-search"
                        label="Rechercher"
                        class="ui-button-raised w-100 search-button"
                        [disabled]="loading"
                        (click)="search()">
                </button>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-6" (click)="onSort()">
                Propriété (job title)
                <span class="pi pi-caret-up" *ngIf="isSortAscending"></span>
                <span class="pi pi-caret-down" *ngIf="!isSortAscending"></span>
            </div>
            <div class="ui-g-6">
                Valeur
            </div>
        </div>
    </p-header>
    <ng-template let-rowToPrint pTemplate="listItem" class="data-container">
        <div class="data-row">
            <div class="ui-g">
                <div class="ui-g-6">
                    {{rowToPrint.label}}
                </div>
                <div class="ui-g-6">
                    {{rowToPrint.value}}
                </div>
            </div>
        </div>
    </ng-template>
</p-dataView>
