import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactScreenComponent} from './contact-screen/contact-screen.component';
import {FeaturesModule} from "../../features/features.module";
import {ComponentsModule} from "../../components/components.module";
import {SharedModule} from "primeng/api";


@NgModule({
    declarations: [ContactScreenComponent],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class ContactModule {
}
