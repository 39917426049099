import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../../../services/state/state.service";
import {ISalesHistory} from "../../../@entities/isales-history";

@Component({
    selector: 'app-sales-history-details',
    templateUrl: './sales-history-details.component.html'
})
export class SalesHistoryDetailsComponent implements OnInit {
    loading: any;
    title: any;
    value: ISalesHistory;

    constructor(
        private router: Router,
        private stateService: StateService
    ) {
    }

    ngOnInit(): void {
        this.value = this.stateService.data;
        if (!this.value) {
            this.router.navigateByUrl('/sales-history');
        }
    }

    setTitle() {
        if (this.value) {
            this.title = this.value.account;
        }
    }
}
