import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {ComponentsModule} from "../components/components.module";
import {MyTabViewModule} from "./tab-view/my-tab-view.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MyTabViewModule,
        ComponentsModule
    ], exports : [
        MyTabViewModule
    ]
})
export class FeaturesModule {
}
