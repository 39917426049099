import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {StorageService} from "../../storage/storage.service";
import {Observable} from "rxjs";
import {Page} from "../../../@entities/Pageable/page";
import {HttpParams} from "@angular/common/http";
import {IContact} from "../../../@entities/icontact";

@Injectable({
    providedIn: 'root'
})
export class ContactManagerService {
    api = environment.apiUrl + environment.resourceUri.contact;

    constructor(private storageService: StorageService) {
    }

    read(id?: number): Observable<IContact>;
    read(id?: any): Observable<IContact> | Observable<IContact[]> {
        return this.storageService.read(this.api + '/query/' + id);
    }

    readByPage(page: Page<IContact>): Observable<Page<IContact>> {
        return this.storageService.readWithParams(this.api + '/query/list', this.createParams(page));
    }

    private createParams(page: Page<IContact>) {
        let params = new HttpParams();

        if (page.number || page.number === 0) {
            params = params.set('pageNumber', page.number.toString());
        }
        if (page.size) {
            params = params.set('pageSize', page.size.toString());
        }
        if (page.filter) {
            page.filter.forEach(filter => {
                if (filter.value !== '') {
                    params = params.set(filter.name, filter.value);
                }
            });
        }
        return params;
    }

    import(file: any): Observable<any> {
        return this.storageService.import(this.api + '/file', file);
    }

    deleteAllContactWithCustomerNumber(data: IContact): Observable<any> {
        let params = new HttpParams();
        params = params.set('customerNumber', String(data.customerNumber));
        return this.storageService.deleteWithParams(this.api + '/delete', params);
    }

    deleteOneContact(data: IContact): Observable<any> {
        let params = new HttpParams();
        params = params.set('id', String(data.id));
        return this.storageService.deleteWithParams(this.api + '/deleteOne', params);
    }
}
