<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/pricing'"
        [loading]="loading"
        [page]="page"
        [title]="'Tarifications'"
        [values]="pricing"
        (searchItemEmitter)="searchPricing()"
        (sortEventEmitter)="sortPricing($event)"
        (loadItemPageEmitter)="loadPricingPage($event)"
        (launchBatchEmitter)="launchBatch()"
        [displayImport]="false"
        [displayLaunchImport]="true"
        [detailPage]="true"
        [sortData]="true">
</app-list-tab-view>
