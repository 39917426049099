import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {StateService} from "../../../services/state/state.service";
import {Page} from "../../../@entities/Pageable/page";
import {IOrderLine} from "../../../@entities/iorder-line";
import {Action} from "../../../services/dispatcher/action";
import {ActionTypes} from "../../../services/dispatcher/action-types.enum";
import {Filter} from "../../../@entities/Pageable/filter";
import {DispatcherService} from "../../../services/dispatcher/dispatcher.service";

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html'
})
export class OrderDetailsComponent implements OnInit {

    loading = false;
    defaultListSize = 25;
    orderline: IOrderLine[];
    page: Page<IOrderLine> = new Page();
    cols = [
        {header: 'Identifiant article', field: 'productId'},
        {header: 'Quantité', field: 'quantity'},
        {header: 'Réduction', field: 'discountOnLineRate'},
        {header: 'Montant Avoir', field: 'amount'}
    ];

    criteriaFields = [
        {name: 'productId', placeHolder: 'Identifiant article', type: 'input', value: ''},
        {name: 'quantity', placeHolder: 'Quantité', type: 'input', value: ''},
        {name: 'discountOnLineRate', placeHolder: 'Réduction', type: 'input', value: ''},
        {name: 'amount', placeHolder: 'Montant Avoir', type: 'input', value: ''},
    ];
    private selectedOrderLine: IOrderLine;

    orderId: number;

    constructor(private router: Router,
                private stateService: StateService,
                private dispatcher: DispatcherService,
                private activatedRoute: ActivatedRoute) {
        let value;
        this.page.size = 25;
        this.page.number = 0;
        if (this.stateService.data2) {
            this.stateService.data = this.stateService.data2;
            value = this.stateService.data;
        } else {
            this.stateService.data2 = this.stateService.data;
            value = this.stateService.data;
        }
        if (!value) {
            this.router.navigateByUrl('/order');
        } else {
            this.orderId = value.id;
            this.getOrderLine();
        }
    }

    ngOnInit(): void {
    }

    redirection() {
        this.stateService.data = null;
        this.stateService.data2 = null;
        this.router.navigateByUrl('/order');
    }

    getOrderLine() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_ORDER_LINE_BY_ID, this.page)).toPromise()
            .then((action) => {
                this.page = action.data;
                if (!this.page) {
                    this.selectedOrderLine = null;
                    this.orderline = null;
                } else {
                    this.selectedOrderLine = this.page.content[0];
                    this.orderline = this.page.content;
                }
            }).finally(() => {
            this.loading = false;
        });
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        let filter = new Filter();
        filter.name = 'order.id';
        filter.value = this.orderId;
        filters.push(filter);
        filter = new Filter();
        filter.name = 'pageNumber';
        filter.value = this.page.number;
        filters.push(filter);
        filter = new Filter();
        filter.name = 'pageSize';
        filter.value = this.page.size;
        filters.push(filter);
        return filters;
    }

    public searchOrderLine() {
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.getOrderLine();
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParamsList: Params = {};
        this.criteriaFields.forEach(criteria => {
            if (criteria.type === 'input') {
                if (criteria.value && criteria.value !== '') {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'select') {
                if (criteria.value && criteria.value.length !== 0) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            }
        });
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }


    public loadOrderLinePage(event: { rows: number, first: number }) {
        this.page.number = (event.first !== 0 && event.rows !== undefined) ? (event.first / event.rows) : 0;
        this.page.size = this.defaultListSize;
        this.getOrderLine();
    }

}
