import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MenuSideService {
    sideBarLeftDisplay: boolean;
    sideBarRightDisplay: boolean;

    constructor() {
    }

    public displaySideBarLeft() {
        this.sideBarLeftDisplay = true;
    }

    public displaySideBarRight() {
        this.sideBarRightDisplay = true;
    }

    public hideSideBarLeft() {
        this.sideBarLeftDisplay = false;
    }

    public hideSideBarRight() {
        this.sideBarRightDisplay = false;
    }
}
