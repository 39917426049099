import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-menu-main',
    templateUrl: './menu-main.component.html'
})
export class MenuMainComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }

}
