import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ICustomer} from '../../../@entities/icustomer';
import {StateService} from '../../../services/state/state.service';

@Component({
    selector: 'app-customer-details',
    templateUrl: './customer-details.component.html'
})
export class CustomerDetailsComponent implements OnInit {
    loading: any;
    title: any;
    value: ICustomer;

    constructor(
        private router: Router,
        private stateService: StateService
    ) {
    }

    ngOnInit(): void {
        this.value = this.stateService.data;
        if (!this.value) {
            this.router.navigateByUrl('/customer');
        }
    }

    setTitle() {
        if (this.value) {
            this.title = this.value.customerNumber;
        }
    }
}
