import {Component, OnInit} from '@angular/core';
import {IContact} from '../../../@entities/icontact';
import {Page} from '../../../@entities/Pageable/page';
import {DispatcherService} from '../../../services/dispatcher/dispatcher.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Action} from '../../../services/dispatcher/action';
import {ActionTypes} from '../../../services/dispatcher/action-types.enum';
import {Filter} from '../../../@entities/Pageable/filter';

@Component({
    selector: 'app-contact-screen',
    templateUrl: './contact-screen.component.html'
})
export class ContactScreenComponent implements OnInit {
    loading = false;
    defaultListSize = 25;
    contact: IContact[];
    page: Page<IContact> = new Page();
    sort: { field: string, asc: boolean };
    cols = [
        {header: 'Supprimer', field: 'action'},
        {header: 'Numéro client', field: 'customerNumber'},
        {header: 'Nom', field: 'lastName'},
        {header: 'Prénom', field: 'firstName'},
        {header: 'Email', field: 'email'},
        {header: 'Téléphone 1', field: 'phone1'},
        {header: 'Téléphone 2', field: 'phone2'},
        {header: 'Fonction', field: 'job'},
        {header: 'Principal', field: 'principal'},
        {header: 'Date de modification', field: 'updatedDate'}
    ];

    criteriaFields = [
        {name: 'lastName', placeHolder: 'Nom du contact', type: 'input', value: ''},
        {name: 'firstName', placeHolder: 'Prénom du contact', type: 'input', value: ''},
        {name: 'customerNumber', placeHolder: 'Numero de client', type: 'input', value: ''},
        {name: 'email', placeHolder: 'Email', type: 'input', value: ''},
        {name: 'phone', placeHolder: 'Téléphone', type: 'input', value: ''},
        {name: 'job', placeHolder: 'Fonction', type: 'input', value: ''},
        {name: 'principal', placeHolder: 'Principal', type: 'checkbox', value: null},
        {name: 'updatedDate', placeHolder: 'Date de modification', type: 'input', value: ''}
    ];
    private selectedContact: IContact;

    constructor(
        private dispatcher: DispatcherService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
    }

    getContact() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_CONTACTS, this.page)).toPromise()
            .then((action) => {
                    this.page = action.data != null ? action.data : new Page();
                    this.selectedContact = this.page.content !== undefined ? this.page.content[0] : null;
                    this.contact = this.page.content;
                }
            ).finally(() => {
            this.loading = false;
        });
    }

    public searchContact() {
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.getContact();
        this.setQueryParams();
    }

    public sortContact(event: { field: string, asc: boolean }) {
        this.sort = {field: event.field, asc: event.asc};
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParamsList: Params = {};
        this.criteriaFields.forEach(criteria => {
            if (criteria.type === 'input') {
                if (criteria.value && criteria.value !== '') {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'select') {
                if (criteria.value && criteria.value.length !== 0) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            }
        });
        if (this.sort && this.sort.field !== null) {
            queryParamsList.sort = this.sort.field;
            queryParamsList.sortOrder = this.sort.asc ? 'asc' : 'desc';
        }
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.pageNumber) {
                queryParamsList.pageNumber = param.pageNumber;
            }
        });
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }

    public loadContactPage(event: { rows: number, first: number }) {
        this.page.number = (event.first !== 0 && event.rows !== undefined) ? (event.first / event.rows) : 0;
        this.page.size = this.defaultListSize;
        this.getContact();
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        this.criteriaFields.forEach(criteria => {
            if (criteria.value !== null) {
                const filter = new Filter();
                filter.name = criteria.name;
                filter.value = criteria.value;
                filters.push(filter);
            }
        });
        if (this.sort != null) {
            const sortField = new Filter();
            sortField.name = "sort";
            sortField.value = this.sort.field;
            filters.push(sortField);

            const sortOrder = new Filter();
            sortOrder.name = "sortOrder";
            sortOrder.value = this.sort.asc ? 'asc' : 'desc';
            filters.push(sortOrder);
        }
        return filters;
    }

    deleteContact(event: { data: IContact, number: string }) {
        if (event.number === 'all') {
            this.dispatcher.dispatch(new Action(ActionTypes.DELETE_ALL_CONTACT_WITH_CUSTOMER_NUMBER, event.data)).toPromise()
                .finally(() => {
                    this.getContact();
                });
        } else if (event.number === 'one') {
            this.dispatcher.dispatch(new Action(ActionTypes.DELETE_ONE_CONTACT, event.data)).toPromise()
                .finally(() => {
                    this.getContact();
                });
        }
    }
}
