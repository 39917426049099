import {Component, OnInit} from '@angular/core';
import {Page} from '../../../@entities/Pageable/page';
import {DispatcherService} from '../../../services/dispatcher/dispatcher.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ICustomer} from '../../../@entities/icustomer';
import {Filter} from '../../../@entities/Pageable/filter';
import {ActionTypes} from "../../../services/dispatcher/action-types.enum";
import {Action} from "../../../services/dispatcher/action";

@Component({
    selector: 'app-customer-screen',
    templateUrl: './customer-screen.component.html'
})
export class CustomerScreenComponent implements OnInit {

    loading = false;
    defaultListSize = 25;
    customers: ICustomer[];
    page: Page<ICustomer> = new Page();
    sort: { field: string, asc: boolean };
    cols = [
        {header: 'Satut', field: 'status'},
        {header: 'Numéro client', field: 'customerNumber'},
        {header: 'Code Enseigne', field: 'shopSignCode'},
        {header: 'Description enseigne', field: 'shopSignDesc'},
        {header: 'Raison sociale', field: 'legalName'},
        {header: 'Adresse 1', field: 'address1'},
        {header: 'Adresse 2', field: 'address2'},
        {header: 'Adresse 3', field: 'address3'},
        {header: 'Adresse 4', field: 'address4'},
        {header: 'Code postal', field: 'postalCode'},
        {header: 'Ville', field: 'city'},
        {header: 'Pays', field: 'country'},
        {header: 'Numéro de téléphone', field: 'phoneNumber'},
        {header: 'Fax', field: 'fax'},
        {header: 'Email', field: 'email'},
        {header: 'Code catalogue client', field: 'customerCatalogueCode'},
        {header: 'Description catalogue client', field: 'customerCatalogueDescription'},
        {header: 'Code sous sous centrale', field: 'subSubCentralCode'},
        {header: 'Description sous sous centrale', field: 'subSubCentralDescription'},
        {header: 'Code sous centrale', field: 'subCentralCode'},
        {header: 'Description sous centrale', field: 'subCentralDescription'},
        {header: 'Code centrale', field: 'centralCode'},
        {header: 'Description centrale', field: 'centralDescription'},
        {header: 'Code grande centrale', field: 'bigCentralCode'},
        {header: 'Description grande centrale', field: 'bigCentralDescription'},
        {header: 'Code circuit', field: 'networkCode'},
        {header: 'Description circuit', field: 'networkDescription'},
        {header: 'Lundi', field: 'monday'},
        {header: 'Mardi', field: 'tuesday'},
        {header: 'Mercredi', field: 'wednesday'},
        {header: 'Jeudi', field: 'thursday'},
        {header: 'Vendredi', field: 'friday'},
        {header: 'Confirmation automatique', field: 'autoConfCmd'},
        {header: 'Contentieux client', field: 'litigationCustomer'},
        {header: 'Code vendeur 1', field: 'seller1Code'},
        {header: 'Description vendeur 1', field: 'seller1Desc'},
        {header: 'Code vendeur 2', field: 'seller2Code'},
        {header: 'Description vendeur 2', field: 'seller2Desc'},
        {header: 'Code vendeur 3', field: 'seller3Code'},
        {header: 'Description vendeur 3', field: 'seller3Desc'},
        {header: 'Ancien Id', field: 'oldCustomerNumber'}
    ];

    criteriaFields = [
        {name: 'status', placeHolder: 'Statut', type: 'input', value: ''},
        {name: 'customerNumber', placeHolder: 'Numéro client', type: 'input', value: ''},
        {name: 'shopSignCode', placeHolder: 'Code Enseigne', type: 'input', value: ''},
        {name: 'shopSignDesc', placeHolder: 'Description enseigne', type: 'input', value: ''},
        {name: 'legalName', placeHolder: 'Raison sociale', type: 'input', value: ''},
        {name: 'address1', placeHolder: 'Adresse 1', type: 'input', value: ''},
        {name: 'address2', placeHolder: 'Adresse 2', type: 'input', value: ''},
        {name: 'address3', placeHolder: 'Adresse 3', type: 'input', value: ''},
        {name: 'address4', placeHolder: 'Adresse 4', type: 'input', value: ''},
        {name: 'postalCode', placeHolder: 'Code postal', type: 'input', value: ''},
        {name: 'city', placeHolder: 'Ville', type: 'input', value: ''},
        {name: 'country', placeHolder: 'Pays', type: 'input', value: ''},
        {name: 'phoneNumber', placeHolder: 'Numéro de téléphone', type: 'input', value: ''},
        {name: 'fax', placeHolder: 'Fax', type: 'input', value: ''},
        {name: 'email', placeHolder: 'Email', type: 'input', value: ''},
        {name: 'customerCatalogueCode', placeHolder: 'Code catalogue client', type: 'input', value: ''},
        {name: 'customerCatalogueDescription', placeHolder: 'Description catalogue client', type: 'input', value: ''},
        {name: 'subSubCentralCode', placeHolder: 'Code sous sous centrale', type: 'input', value: ''},
        {name: 'subSubCentralDescription', placeHolder: 'Description sous sous centrale', type: 'input', value: ''},
        {name: 'subCentralCode', placeHolder: 'Code sous centrale', type: 'input', value: ''},
        {name: 'subCentralDescription', placeHolder: 'Description sous centrale', type: 'input', value: ''},
        {name: 'centralCode', placeHolder: 'Code centrale', type: 'input', value: ''},
        {name: 'centralDescription', placeHolder: 'Description centrale', type: 'input', value: ''},
        {name: 'bigCentralCode', placeHolder: 'Code grande centrale', type: 'input', value: ''},
        {name: 'bigCentralDescription', placeHolder: 'Description grande centrale', type: 'input', value: ''},
        {name: 'networkCode', placeHolder: 'Code circuit', type: 'input', value: ''},
        {name: 'networkDescription', placeHolder: 'Description circuit', type: 'input', value: ''},
        {name: 'monday', placeHolder: 'Lundi', type: 'checkbox', value: null},
        {name: 'tuesday', placeHolder: 'Mardi', type: 'checkbox', value: null},
        {name: 'wednesday', placeHolder: 'Mercredi', type: 'checkbox', value: null},
        {name: 'thursday', placeHolder: 'Jeudi', type: 'checkbox', value: null},
        {name: 'friday', placeHolder: 'Vendredi', type: 'checkbox', value: null},
        {name: 'autoConfCmd', placeHolder: 'Confirmation automatique', type: 'checkbox', value: null},
        {name: 'litigationCustomer', placeHolder: 'Contentieux client', type: 'checkbox', value: null},
        {name: 'seller1Code', placeHolder: 'Code vendeur 1', type: 'input', value: ''},
        {name: 'seller1Desc', placeHolder: 'Description vendeur 1', type: 'input', value: ''},
        {name: 'seller2Code', placeHolder: 'Code vendeur 2', type: 'input', value: ''},
        {name: 'seller2Desc', placeHolder: 'Description vendeur 2', type: 'input', value: ''},
        {name: 'seller3Code', placeHolder: 'Code vendeur 3', type: 'input', value: ''},
        {name: 'seller3Desc', placeHolder: 'Description vendeur 3', type: 'input', value: ''},
        {name: 'oldCustomerNumber', placeHolder: 'Ancien Id', type: 'input', value: ''}
    ];
    private selectedCustomer: ICustomer;

    constructor(
        private dispatcher: DispatcherService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
    }

    getCustomer() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_ALL_CUSTOMER, this.page)).toPromise()
            .then((action) => {
                this.page = action.data;
                if (!this.page) {
                    this.selectedCustomer = null;
                    this.customers = null;
                } else {
                    this.selectedCustomer = this.page.content[0];
                    this.customers = this.page.content;
                }
            }).finally(() => {
            this.loading = false;
        });
    }

    public searchCustomer() {
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
        this.getCustomer();
    }

    public sortCustomer(event: { field: string, asc: boolean }) {
        this.sort = {field: event.field, asc: event.asc};
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParamsList: Params = {};
        this.criteriaFields.forEach(criteria => {
            if (criteria.type === 'input') {
                if (criteria.value && criteria.value !== '') {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'select') {
                if (criteria.value && criteria.value.length !== 0) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'checkbox') {
                if (criteria.value !== null) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            }
        });
        if (this.sort && this.sort.field !== null) {
            queryParamsList.sort = this.sort.field;
            queryParamsList.sortOrder = this.sort.asc ? 'asc' : 'desc';
        }
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.pageNumber) {
                queryParamsList.pageNumber = param.pageNumber;
            }
        });
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }


    public loadCustomerPage(event: { rows: number, first: number }) {
        this.page.number = (event.first !== 0 && event.rows !== undefined) ? (event.first / event.rows) : 0;
        this.page.size = this.defaultListSize;
        this.getCustomer();
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        this.criteriaFields.forEach(criteria => {
            if (criteria.value !== null) {
                const filter = new Filter();
                filter.name = criteria.name;
                filter.value = criteria.value;
                filters.push(filter);
            }
        });
        if (this.sort != null) {
            const sortField = new Filter();
            sortField.name = "sort";
            sortField.value = this.sort.field;
            filters.push(sortField);

            const sortOrder = new Filter();
            sortOrder.name = "sortOrder";
            sortOrder.value = this.sort.asc ? 'asc' : 'desc';
            filters.push(sortOrder);
        }
        return filters;
    }


    launchBatch() {
        this.dispatcher.dispatch(new Action(ActionTypes.LAUNCH_BATCH, 'customers')).toPromise()
            .then(() => {
            });
    }

}
