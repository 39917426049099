import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReferenceScreenComponent} from './reference-screen/reference-screen.component';
import {SharedModule} from "../../shared/shared.module";
import {ComponentsModule} from "../../components/components.module";
import {FeaturesModule} from "../../features/features.module";


@NgModule({
    declarations: [ReferenceScreenComponent],
    imports: [
        CommonModule,
        SharedModule,
        ComponentsModule,
        FeaturesModule
    ]
})
export class ReferenceModule {
}
