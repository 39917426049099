<div *ngIf="isImport" class="p-grid p-justify-center">
    <h2 class="p-col-12">Imports</h2>
    <br/>
    <a *ngFor="let item of importItems" class="p-align-center tile" routerLink="/{{item.routerLink}}">
        <i class="{{item.icon}}"></i>
        <h2>{{item.label}}</h2>
    </a>
</div>
<hr/>
<div *ngIf="isAdmin" class="p-grid p-justify-center">
    <h2 class="p-col-12">Administration</h2>
    <br/>
    <a *ngFor="let item of adminItems" class="p-align-center tile" routerLink="/{{item.routerLink}}">
        <i class="{{item.icon}}"></i>
        <h2>{{item.label}}</h2>
    </a>
</div>
