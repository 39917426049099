<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<ng-container *ngIf="sortData; else NoSortData">
    <p-table
        #tb
        [columns]="cols"
        [rows]="nbRows"
        [totalRecords]="totalRows"
        [paginator]="true"
        [lazy]="true"
        (onLazyLoad)="callLazyLoading($event)"
        [value]="values"
        class="table-component"
        [responsive]="true"
        [autoLayout]="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Elements {first} à {last} sur {totalRecords} au total"
        [first]="first"
        [loading]="loading"
        (onPage)="onPageChange($event)"
        (onSort)="onSort($event)"
        [sortOrder]="sortOrder"
        [sortField]="sortField">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th scope="col" *ngFor="let col of columns" [pSortableColumn]="col.field"
                    [pSortableColumnDisabled]="col.field === 'action'">
                    {{ col.header | translate }}
                    <p-sortIcon *ngIf="col.field !== 'action'"
                                [field]="col.field"
                                ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-value let-columns="columns">
            <ng-container *ngIf="detailPage; else NoDetailPage">
                <tr (click)="onGoToDetails(value)" class="hoverable-tr ">
                    <td *ngFor="let col of columns">
                    <span *ngIf="col.field != 'action'">
                      {{ value[col.field] }}
                    </span>
                    </td>
                </tr>
            </ng-container>
            <ng-template #NoDetailPage>
                <tr class="hoverable-tr ">
                    <td *ngFor="let col of columns">
                    <span *ngIf="col.field != 'action'">
                        {{ value[col.field] }}
                    </span>
                        <div class="w-100 div-action" *ngIf="col.field === 'action'">
                            <button #btn
                                    type="button"
                                    pButton
                                    icon="pi pi-bars"
                                    class="button-action"
                                    (click)="setSelectedItem(value);menu.toggle($event);">
                            </button>
                            <p-tieredMenu [showTransitionOptions]="'300ms'"
                                          [hideTransitionOptions]="'300ms'" #menu
                                          [model]="items"
                                          [popup]="true"></p-tieredMenu>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length">
                    Pas de données trouvées
                </td>
            </tr>
        </ng-template>
    </p-table>
</ng-container>

<ng-template #NoSortData>
    <p-table
        #tb
        [columns]="cols"
        [rows]="nbRows"
        [totalRecords]="totalRows"
        [paginator]="true"
        [lazy]="true"
        (onLazyLoad)="callLazyLoading($event)"
        [value]="values"
        class="table-component"
        [responsive]="true"
        [autoLayout]="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [first]="first"
        [loading]="loading"
        (onPage)="onPageChange($event)">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th scope="col" *ngFor="let col of columns" [pSortableColumn]="col.field"
                    [pSortableColumnDisabled]="col.field === 'action'">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-value let-columns="columns">
            <ng-container *ngIf="detailPage; else NoDetailPage">
                <tr (click)="onGoToDetails(value)" class="hoverable-tr ">
                    <td *ngFor="let col of columns">
                    <span *ngIf="col.field != 'action'">
                      {{ value[col.field] }}
                    </span>
                    </td>
                </tr>
            </ng-container>
            <ng-template #NoDetailPage>
                <tr class="hoverable-tr ">
                    <td *ngFor="let col of columns">
                    <span *ngIf="col.field != 'action'">
                        {{ value[col.field] }}
                    </span>
                        <div class="w-100 div-action" *ngIf="col.field === 'action'">
                            <button #btn
                                    type="button"
                                    pButton
                                    icon="pi pi-bars"
                                    class="button-action"
                                    (click)="setSelectedItem(value);menu.toggle($event);">
                            </button>
                            <p-tieredMenu [showTransitionOptions]="'300ms'"
                                          [hideTransitionOptions]="'300ms'" #menu
                                          [model]="items"
                                          [popup]="true"></p-tieredMenu>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length">
                    Pas de données trouvées
                </td>
            </tr>
        </ng-template>
    </p-table>
</ng-template>
