import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {MenuSideService} from '../services/menu-side.service';
import {AuthenticationService} from "src/app/services/authentication/authentication.service";
import {Role} from "src/app/@entities/role";

@Component({
    selector: 'app-menu-side-left',
    templateUrl: './menu-side-left.component.html',
    styleUrls: [
        './menu-side-left.component.scss'
    ]
})
export class MenuSideLeftComponent implements OnInit {

    title = 'Menu';
    items: MenuItem[];
    display: boolean;

    constructor(public sideBarService: MenuSideService,
                private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        const role: Role = this.authenticationService.role;
        const command = (onclick) => {
            this.sideBarService.hideSideBarLeft();
        };

        this.items = [
            {label: 'Home', icon: 'pi pi-home', routerLink: 'home', command}
        ];

        const adminItems: MenuItem[] = [
            {label: 'Articles', icon: 'pi pi-briefcase', routerLink: 'product', command},
            {label: 'Clients', icon: 'pi pi-users', routerLink: 'customer', command},
            {label: 'Commandes', icon: 'pi pi-file-o', routerLink: 'order', command},
            {label: 'Mises à jour commande', icon: 'pi pi-copy', routerLink: 'updated-orders', command},
            {label: 'Fonds de broche', icon: 'pi pi-tags', routerLink: 'spit-background', command},
            {label: 'Historiques de vente', icon: 'pi pi-clock', routerLink: 'sales-history', command},
            {label: 'Tarifications', icon: 'pi pi-money-bill', routerLink: 'pricing', command},
            {label: 'Logins', icon: 'pi pi-key', routerLink: 'login', command}
        ];

        const importItems: MenuItem[] = [
            {label: 'Référencements', icon: 'pi pi-list', routerLink: 'reference', command},
            {label: 'Contacts', icon: 'pi pi-id-card', routerLink: 'contact', command},
            {label: 'Modèles de commande', icon: 'pi pi-sitemap', routerLink: 'order-model', command},
            {label: 'Executions', icon: 'pi pi-cog', routerLink: 'execution', command}
        ];

        if (role === Role.ADMIN) {
            this.items = this.items.concat(adminItems).concat(importItems);
        } else if (role === Role.IMPORT) {
            this.items = this.items.concat(importItems);
        }
    }

}
