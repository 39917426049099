<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/sales-history'"
        [loading]="loading"
        [page]="page"
        [title]="'Historique des ventes'"
        [values]="salesHistory"
        (searchItemEmitter)="searchSalesHistory()"
        (sortEventEmitter)="sortSalesHistory($event)"
        (loadItemPageEmitter)="loadSalesHistoryPage($event)"
        (launchBatchEmitter)="launchBatch()"
        [displayImport]="false"
        [displayLaunchImport]="true"
        [detailPage]="true"
        [sortData]="true">
</app-list-tab-view>

