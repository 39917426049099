import { Sort } from './sort';
import { Pageable } from './pageable';
import { Filter } from './filter';

export class Page<T> {
    content: Array<T>;
    pageable: Pageable;
    last: boolean;
    totalPages: number;
    totalElements: number;
    first: boolean;
    sort: Sort;
    numberOfElements: number;
    size: number;
    number: number;
    filter: Array<Filter>;
    public constructor() {
        this.pageable = new Pageable();
    }
}

