import {Component, OnInit} from '@angular/core';
import {Page} from "../../../@entities/Pageable/page";
import {DispatcherService} from "../../../services/dispatcher/dispatcher.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Action} from "../../../services/dispatcher/action";
import {ActionTypes} from "../../../services/dispatcher/action-types.enum";
import {Filter} from "../../../@entities/Pageable/filter";
import {ISpitBackground} from "../../../@entities/i-spit-background";

@Component({
    selector: 'app-spit-background-screen',
    templateUrl: './spit-background-screen.component.html'
})
export class SpitBackgroundScreenComponent implements OnInit {

    loading = false;
    defaultListSize = 25;
    spitBackground: ISpitBackground[];
    page: Page<ISpitBackground> = new Page();
    sort: { field: string, asc: boolean };
    cols = [
        {header: 'Client', field: 'customer'},
        {header: 'Identifiant article', field: 'productId'},
        {header: 'Référence commande', field: 'orderReference'},
        {header: 'Numéro de commande', field: 'orderNumber'},
        {header: 'Envoyé à Gildas', field: 'sentToGildas'}
    ];

    criteriaFields = [
        {name: 'customer', placeHolder: 'Client', type: 'input', value: ''},
        {name: 'productId', placeHolder: 'Identifiant article', type: 'input', value: ''},
        {name: 'orderReference', placeHolder: 'Référence commande', type: 'input', value: ''},
        {name: 'orderNumber', placeHolder: 'Numéro de commande', type: 'input', value: ''},
        {
            name: 'sentToGildas',
            placeHolder: 'Envoyé à Gildas',
            type: 'select',
            values: [
                {label: 'Tout', value: 'ALL'},
                {label: 'Envoyé', value: 'SENT'},
                {label: 'Non envoyé', value: 'NOT_SENT'},
                {label: 'En cours', value: 'PROCESSING'}
            ],
            value: ''
        },
    ];
    private selectedSpitBackground: ISpitBackground;

    constructor(
        private dispatcher: DispatcherService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
    }

    getSpitBackground() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_ALL_SPIT_BACKGROUND, this.page)).toPromise()
            .then((action) => {
                this.page = action.data;
                if (!this.page) {
                    this.selectedSpitBackground = null;
                    this.spitBackground = null;
                } else {
                    this.selectedSpitBackground = this.page.content[0];
                    this.spitBackground = this.page.content;
                }
            }).finally(() => {
            this.loading = false;
        });
    }

    public searchSpitBackground() {
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
        this.getSpitBackground();
    }

    public sortSpitBackground(event: { field: string, asc: boolean }) {
        this.sort = {field: event.field, asc: event.asc};
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParamsList: Params = {};
        this.criteriaFields.forEach(criteria => {
            if (criteria.type === 'input') {
                if (criteria.value && criteria.value !== '' && criteria.value !== null) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'select') {
                if (criteria.value && criteria.value.length !== 0) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'checkbox') {
                if (criteria.value !== null) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            }
        });
        if (this.sort && this.sort.field !== null) {
            queryParamsList.sort = this.sort.field;
            queryParamsList.sortOrder = this.sort.asc ? 'asc' : 'desc';
        }
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.pageNumber) {
                queryParamsList.pageNumber = param.pageNumber;
            }
        });
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }


    public loadSpitBackgroundPage(event: { rows: number, first: number }) {
        this.page.number = (event.first !== 0 && event.rows !== undefined) ? (event.first / event.rows) : 0;
        this.page.size = this.defaultListSize;
        this.getSpitBackground();
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        this.criteriaFields.forEach(criteria => {
            if (criteria.value !== null && criteria.value !== 'ALL') {
                const filter = new Filter();
                filter.value = criteria.value;
                filter.name = criteria.name;
                filters.push(filter);
            }
        });
        if (this.sort != null) {
            const sortField = new Filter();
            sortField.name = "sort";
            sortField.value = this.sort.field;
            filters.push(sortField);

            const sortOrder = new Filter();
            sortOrder.name = "sortOrder";
            sortOrder.value = this.sort.asc ? 'asc' : 'desc';
            filters.push(sortOrder);
        }
        return filters;
    }

    launchBatch() {
        this.dispatcher.dispatch(new Action(ActionTypes.LAUNCH_BATCH, 'spit-background')).toPromise()
            .then(() => {
            });
    }

}
