import {Component, OnInit} from '@angular/core';
import {Page} from "../../../@entities/Pageable/page";
import {IProduct} from "../../../@entities/iproduct";
import {Router} from "@angular/router";
import {StateService} from "../../../services/state/state.service";
import {Action} from "../../../services/dispatcher/action";
import {ActionTypes} from "../../../services/dispatcher/action-types.enum";
import {DispatcherService} from "../../../services/dispatcher/dispatcher.service";
import {Filter} from "../../../@entities/Pageable/filter";

@Component({
    selector: 'app-product-kit-detail',
    templateUrl: './product-kit-details.component.html'
})
export class ProductKitDetailsComponent implements OnInit {

    page: Page<IProduct> = new Page();
    loading: any;
    title: any;
    value: IProduct;
    products: IProduct[];
    private idProduct: string;

    constructor(
        private router: Router,
        private stateService: StateService,
        private dispatcher: DispatcherService) {
    }

    ngOnInit(): void {
        if (!this.stateService.data3) {
            this.router.navigateByUrl('/product');
        } else {
            this.idProduct = this.stateService.data3.productId;
            this.getProduct();
        }
    }

    setTitle() {
        if (this.value) {
            this.title = this.value.productId;
        }
    }

    getProduct() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_ALL_PRODUCTS, this.page)).toPromise()
            .then((action) => {
                this.page = action.data;
                if (!this.page) {
                    this.products = null;
                } else {
                    this.products = this.page.content;
                }
            }).finally(() => {
            this.loading = false;
            this.value = this.products[0];
        });
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        let filter = new Filter();
        filter.name = 'productId';
        filter.value = this.idProduct;
        filters.push(filter);
        filter = new Filter();
        filter.name = 'pageNumber';
        filter.value = 0;
        filters.push(filter);
        filter = new Filter();
        filter.name = 'pageSize';
        filter.value = 25;
        filters.push(filter);
        return filters;
    }
}
