import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Page} from '../../../@entities/Pageable/page';
import {Router} from '@angular/router';

@Component({
    selector: 'app-list-tab-view',
    templateUrl: './list-tab-view.component.html',
    styleUrls: ['../my-tab-view.scss']
})
export class ListTabViewComponent implements OnInit {
    @Input() cols: any;
    @Input() criteriaFields: any;
    @Input() detailsBaseURL: any;
    @Input() importName: string;
    @Input() loading: boolean;
    pageSize: number;
    pageNumber: number;
    pageTotalElements: number;
    pages: Page<any>;

    @Input() set page(value: Page<any>) {
        this.pages = value;
        if (value) {
            this.pageSize = value.size;
            this.pageNumber = value.number;
            this.pageTotalElements = value.totalElements;
        } else {
            this.pageSize = 0;
            this.pageNumber = 0;
            this.pageTotalElements = 0;
        }
    }

    get page(): Page<any> {
        return this.pages;
    }

    @Input() title: string;
    @Input() values: any;
    @Output() searchItemEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() sortEventEmitter: EventEmitter<{ field: string, asc: boolean }> = new EventEmitter<{ field: string, asc: boolean }>();
    @Output() loadItemPageEmitter: EventEmitter<{ rows: number, first: number }> = new EventEmitter<{ rows: number, first: number }>(true);
    @Output() launchBatchEmitter: EventEmitter<string> = new EventEmitter<string>();
    @Input() displayImport: boolean;
    @Input() displayLaunchImport = false;
    @Input() displayLaunchExport = false;
    @Input() detailPage: boolean;
    @Output() deleteItemEmitter: EventEmitter<{ data: any, number: string }> = new EventEmitter<{ data: any, number: string }>();
    @Input() filter = true;
    @Input() sortData: boolean;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    sortItem(event: { field: string, asc: boolean }) {
        this.sortEventEmitter.emit(event);
    }

    searchItem(event: any) {
        this.searchItemEmitter.emit(event);
    }

    loadItemPage(event: any) {
        this.loadItemPageEmitter.emit(event);
    }

    redirection(event: number) {
        this.router.navigateByUrl(this.detailsBaseURL + '/:' + event);
    }

    deleteItem(event: { data: any, number: string }) {
        this.deleteItemEmitter.emit(event);
    }
}
