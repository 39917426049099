import {Component, OnInit} from '@angular/core';
import {IPricing} from '../../../@entities/ipricing';
import {DispatcherService} from '../../../services/dispatcher/dispatcher.service';
import {Page} from '../../../@entities/Pageable/page';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Filter} from '../../../@entities/Pageable/filter';
import {ActionTypes} from '../../../services/dispatcher/action-types.enum';
import {Action} from '../../../services/dispatcher/action';

@Component({
    selector: 'app-pricing-screen',
    templateUrl: './pricing-screen.component.html'
})


export class PricingScreenComponent implements OnInit {
    loading = false;
    defaultListSize = 25;
    pricing: IPricing[];
    page: Page<IPricing> = new Page();
    sort: { field: string, asc: boolean };
    cols = [
        {header: 'Nom', field: 'pricingName'},
        {header: 'Identifiant article', field: 'productId'},
        {header: 'Groupe de client', field: 'customerGroup'},
        {header: 'Critère Client 1', field: 'customerCriteria1'},
        {header: 'Critère Client 2', field: 'customerCriteria2'},
        {header: 'Groupe de l\'article', field: 'productGroup'},
        {header: 'Critère Article 1', field: 'productCriteria1'},
        {header: 'Critère Article 2', field: 'productCriteria2'},
        {header: 'Groupe de commande', field: 'orderGroup'},
        {header: 'Critère Commande 1', field: 'orderCriteria1'},
        {header: 'Critère Commande 2', field: 'orderCriteria2'},
        {header: 'ID Technique', field: 'technicalId'},
        {header: 'Ordonnancement', field: 'scheduling'},
        {header: 'Prochaine étape', field: 'nextStep'},
        {header: 'Palier minimum', field: 'minLevel'},
        {header: 'Palier maximum', field: 'maxLevel'},
        {header: 'Taux de remise', field: 'discountRate'},
        {header: 'Montant', field: 'amount'},
        {header: 'Date de début', field: 'startDate'},
        {header: 'Date de fin', field: 'endDate'},
        {header: 'Code article du kit', field: 'kitCode'},
        {header: 'Code CC1 Client', field: 'customerCcCode1'},
        {header: 'Code CC2 Client', field: 'customerCcCode2'},
        {header: 'Code CC1 Article', field: 'productCcCode1'},
        {header: 'Code CC2 Article', field: 'productCcCode2'},
        {header: 'Code CC1 Commande', field: 'orderCcCode1'},
        {header: 'Code CC2 Commande', field: 'orderCcCode2'}
    ];

    criteriaFields = [
        {name: 'pricingName', placeHolder: 'Nom', type: 'input', value: ''},
        {name: 'productId', placeHolder: 'Identifiant article', type: 'input', value: ''},
        {name: 'customerGroup', placeHolder: 'Groupe de client', type: 'input', value: ''},
        {name: 'customerCriteria', placeHolder: 'Critère Client', type: 'input', value: ''},
        {name: 'productGroup', placeHolder: 'Groupe de l\'article', type: 'input', value: ''},
        {name: 'productCriteria', placeHolder: 'Critère Article', type: 'input', value: ''},
        {name: 'orderGroup', placeHolder: 'Groupe de commande', type: 'input', value: ''},
        {name: 'orderCriteria', placeHolder: 'Critère Commande', type: 'input', value: ''},
        {name: 'technicalId', placeHolder: 'ID Technique', type: 'input', value: ''},
        {name: 'scheduling', placeHolder: 'Ordonnancement', type: 'input', value: ''},
        {name: 'nextStep', placeHolder: 'Prochaine étape', type: 'input', value: ''},
        {name: 'minLevel', placeHolder: 'Palier minimum', type: 'input', value: ''},
        {name: 'maxLevel', placeHolder: 'Palier maximum', type: 'input', value: ''},
        {name: 'discountRate', placeHolder: 'Taux de remise', type: 'input', value: ''},
        {name: 'amount', placeHolder: 'Montant', type: 'input', value: ''},
        {name: 'startDate', placeHolder: 'Date de début', type: 'input', value: ''},
        {name: 'endDate', placeHolder: 'Date de fin', type: 'input', value: ''},
        {name: 'kitCode', placeHolder: 'Code article du kit', type: 'input', value: ''},
        {name: 'customerCcCode', placeHolder: 'Code CC Client', type: 'input', value: ''},
        {name: 'productCcCode', placeHolder: 'Code CC Article', type: 'input', value: ''},
        {name: 'orderCcCode', placeHolder: 'Code CC Commande', type: 'input', value: ''},

    ];
    private selectedPricing: IPricing;

    constructor(
        private dispatcher: DispatcherService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
    }

    getPricing() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_ALL_PRICING, this.page)).toPromise()
            .then((action) => {
                this.page = action.data;
                if (!this.page) {
                    this.selectedPricing = null;
                    this.pricing = null;
                } else {
                    this.selectedPricing = this.page.content[0];
                    this.pricing = this.page.content;
                }
            }).finally(() => {
            this.loading = false;
        });
    }

    public searchPricing() {
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
        this.getPricing();
    }

    public sortPricing(event: { field: string, asc: boolean }) {
        this.sort = {field: event.field, asc: event.asc};
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParamsList: Params = {};
        this.criteriaFields.forEach(criteria => {
            if (criteria.type === 'input') {
                if (criteria.value && criteria.value !== '' && criteria.value !== null) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'select') {
                if (criteria.value && criteria.value.length !== 0) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            }
        });
        if (this.sort && this.sort.field !== null) {
            queryParamsList.sort = this.sort.field;
            queryParamsList.sortOrder = this.sort.asc ? 'asc' : 'desc';
        }
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.pageNumber) {
                queryParamsList.pageNumber = param.pageNumber;
            }
        });
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }


    public loadPricingPage(event: { rows: number, first: number }) {
        this.page.number = (event.first !== 0 && event.rows !== undefined) ? (event.first / event.rows) : 0;
        this.page.size = this.defaultListSize;
        this.getPricing();
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        this.criteriaFields.forEach(criteria => {
            if (criteria.value !== null) {
                const filter = new Filter();
                filter.name = criteria.name;
                filter.value = criteria.value;
                filters.push(filter);
            }
        });
        if (this.sort != null) {
            const sortField = new Filter();
            sortField.name = "sort";
            sortField.value = this.sort.field;
            filters.push(sortField);

            const sortOrder = new Filter();
            sortOrder.name = "sortOrder";
            sortOrder.value = this.sort.asc ? 'asc' : 'desc';
            filters.push(sortOrder);
        }
        return filters;
    }

    launchBatch() {
        this.dispatcher.dispatch(new Action(ActionTypes.LAUNCH_BATCH, 'pricing')).toPromise()
            .then(() => {
            });
    }

}
