import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {ComponentsModule} from './components/components.module';
import {ScreensModule} from './screens/screens.module';
import {SharedModule} from './shared/shared.module';
import {MessageService} from 'primeng/api';
import {AppConfig} from './app.config';
import {AuthenticationGuard, MsAdalAngular6Service} from 'microsoft-adal-angular6';
import {InsertAuthTokenInterceptor} from './services/interceptor/insert-auth-token-interceptor.service';
import {ErrorInterceptor} from './services/interceptor/error-interceptor.service';
import {HTTPListener, HTTPStatus} from './services/interceptor/http-listener.service';
import {environment} from '../environments/environment';

const RxJS_Services = [HTTPListener, HTTPStatus];

export function HttpLoaderFactory(http: HttpClient) {
    const dontCacheDuringDev = environment.production ? '' : '?' + new Date();
    return new TranslateHttpLoader(http, './assets/i18n/', '.json' + dontCacheDuringDev);
}

let adalConfig: any; // will be initialized by APP_INITIALIZER
export function msAdalAngular6ConfigFactory() {
    return adalConfig; // will be invoked later when creating MsAdalAngular6Service
}

export function initializeApp(appConfig: AppConfig, translate: TranslateService) {
    const promise = appConfig.load().then(async () => {
        adalConfig = {
            tenant: AppConfig.settings.tenantId,
            clientId: AppConfig.settings.clientId,
            redirectUri: window.location.origin,
            navigateToLoginRequestUrl: true,
            cacheLocation: 'localStorage'
        };
        // store admin groups in session storage
        sessionStorage.setItem('adminGroups', JSON.stringify(AppConfig.settings.adminGroups));
        sessionStorage.setItem('importGroups', JSON.stringify(AppConfig.settings.importGroups));
        const defaultLang = 'fr';
        translate.setDefaultLang(defaultLang);
        await translate.use(defaultLang).toPromise();
    });
    return  () => promise;
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ComponentsModule,
        ScreensModule,
        SharedModule
    ],
    providers: [
        MessageService,
        AppConfig,
        AuthenticationGuard,
        {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig, TranslateService], multi: true},
        MsAdalAngular6Service,
        {
            provide: 'adalConfig',
            useFactory: msAdalAngular6ConfigFactory,
            deps: []
        },
        {provide: HTTP_INTERCEPTORS, useClass: InsertAuthTokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        RxJS_Services,
        {provide: HTTP_INTERCEPTORS, useClass: HTTPListener, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
