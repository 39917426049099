import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PricingScreenComponent} from "./pricing-screen/pricing-screen.component";
import {PricingDetailsComponent} from './pricing-details/pricing-details.component';
import {SharedModule} from "../../shared/shared.module";
import {FeaturesModule} from "../../features/features.module";
import {ComponentsModule} from "../../components/components.module";


@NgModule({
  declarations: [
      PricingScreenComponent,
      PricingDetailsComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class PricingModule { }
