<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/contact'"
        [loading]="loading"
        [page]="page"
        [title]="'Contact'"
        [values]="contact"
        (searchItemEmitter)="searchContact()"
        (sortEventEmitter)="sortContact($event)"
        (loadItemPageEmitter)="loadContactPage($event)"
        [displayImport]="true"
        [importName]="'contact'"
        [detailPage]="false"
        (deleteItemEmitter)="deleteContact($event)"
        [sortData]="true">
</app-list-tab-view>
