export enum ActionTypes {
    ADD,
    UPDATE,
    DELETE,
    GET_ONE,
    GET_ALL,
    ADD_USER,
    GET_USER,
    GET_USERS,
    DELETE_USER,
    UPDATE_USER,
    GET_ALL_PRICING,
    IMPORT_REFERENCE,
    IMPORT_CONTACT,
    IMPORT_ORDER_MODEL,
    GET_CONTACTS,
    GET_ORDER_MODELS,
    GET_REFERENCES,
    IMPORT_STATUS,
    GET_ALL_ORDER,
    GET_ALL_CUSTOMER,
    GET_ALL_LOGIN,
    GET_ALL_SALES_HISTORY,
    GET_ALL_UPDATED_ORDER,
    DELETE_ALL_CONTACT_WITH_CUSTOMER_NUMBER,
    DELETE_ALL_REFERENCE_WITH_NAME,
    DELETE_ALL_ORDER_MODEL_WITH_NAME,
    DELETE_ONE_CONTACT,
    DELETE_ONE_ORDER_MODEL,
    DELETE_ONE_REFERENCE,
    GET_ALL_PRODUCTS,
    GET_ORDER_LINE_BY_ID,
    GET_ALL_SPIT_BACKGROUND,
    GET_ALL_EXECUTION,
    GET_EXECUTION_DETAIL_BY_ID,
    LAUNCH_BATCH
}
