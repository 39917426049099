import {Component, OnInit} from '@angular/core';
import {IOrder} from '../../../@entities/iorder';
import {Page} from '../../../@entities/Pageable/page';
import {DispatcherService} from '../../../services/dispatcher/dispatcher.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ActionTypes} from '../../../services/dispatcher/action-types.enum';
import {Action} from '../../../services/dispatcher/action';
import {Filter} from '../../../@entities/Pageable/filter';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-order-screen',
    templateUrl: './order-screen.component.html'
})
export class OrderScreenComponent implements OnInit {

    loading = false;
    defaultListSize = 25;
    order: IOrder[];
    page: Page<IOrder> = new Page();
    sort: { field: string, asc: boolean };
    cols = [
        {header: 'order.customerOrderReference', field: 'customerOrderReference'},
        {header: 'order.shopFactory', field: 'shopFactory'},
        {header: 'order.customerNumber', field: 'customerNumber'},
        {header: 'order.orderType', field: 'orderType'},
        {header: 'order.proforma', field: 'proforma'},
        {header: 'order.vilmocomOrderNumber', field: 'vilmocomOrderNumber'},
        {header: 'order.askedDate', field: 'askedDate'},
        {header: 'order.orderDate', field: 'orderDate'},
        {header: 'order.discountRate', field: 'discountRate'},
        {header: 'order.comment', field: 'comment'},
        {header: 'order.sentToJde', field: 'sentToJde'}

    ];

    criteriaFields = [
        {name: 'customerOrderReference', placeHolder: 'order.customerOrderReference', type: 'input', value: ''},
        {name: 'shopFactory', placeHolder: 'order.shopFactory', type: 'input', value: ''},
        {name: 'customerNumber', placeHolder: 'order.customerNumber', type: 'input', value: ''},
        {name: 'orderType', placeHolder: 'order.orderType', type: 'input', value: ''},
        {name: 'proforma', placeHolder: 'order.proforma', type: 'checkbox', value: null},
        {name: 'vilmocomOrderNumber', placeHolder: 'order.vilmocomOrderNumber', type: 'input', value: ''},
        {name: 'askedDate', placeHolder: 'order.askedDate', type: 'input', value: ''},
        {name: 'orderDate', placeHolder: 'order.orderDate', type: 'input', value: ''},
        {name: 'discountRate', placeHolder: 'order.discountRate', type: 'input', value: ''},
        {name: 'comment', placeHolder: 'order.comment', type: 'input', value: ''},
        {
            name: 'sentToJde',
            placeHolder: 'order.sentToJde',
            type: 'select',
            value: '',
            values: [
                {label: this.translate.instant('all'), value: 'ALL'},
                {label: this.translate.instant('sent'), value: 'SENT'},
                {label: this.translate.instant('not-sent'), value: 'NOT_SENT'},
                {label: this.translate.instant('processing'), value: 'PROCESSING'},
                {label: this.translate.instant('draft'), value: 'DRAFT'},
                {label: this.translate.instant('deleted'), value: 'DELETE'}
            ]
        }
    ];
    private selectedOrder: IOrder;

    constructor(
        private dispatcher: DispatcherService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
    }

    getOrder() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_ALL_ORDER, this.page)).toPromise()
            .then((action) => {
                this.page = action.data;
                if (!this.page) {
                    this.selectedOrder = null;
                    this.order = null;
                } else {
                    this.selectedOrder = this.page.content[0];
                    this.order = this.page.content;
                }
            }).finally(() => {
            this.loading = false;
        });
    }

    public searchOrder() {
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.getOrder();
        this.setQueryParams();
    }

    public sortOrder(event: { field: string, asc: boolean }) {
        this.sort = {field: event.field, asc: event.asc};
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParamsList: Params = {};
        this.criteriaFields.forEach(criteria => {
            if (criteria.type === 'input') {
                if (criteria.value && criteria.value !== '') {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'select') {
                if (criteria.value && criteria.value.length !== 0) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'checkbox') {
                if (criteria.value !== null) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            }
        });
        if (this.sort && this.sort.field !== null) {
            queryParamsList.sort = this.sort.field;
            queryParamsList.sortOrder = this.sort.asc ? 'asc' : 'desc';
        }
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.pageNumber) {
                queryParamsList.pageNumber = param.pageNumber;
            }
        });
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }


    public loadOrderPage(event: { rows: number, first: number }) {
        this.page.number = (event.first !== 0 && event.rows !== undefined) ? (event.first / event.rows) : 0;
        this.page.size = this.defaultListSize;
        this.getOrder();
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        this.criteriaFields.forEach(criteria => {
            if (criteria.value !== null && criteria.value !== 'ALL') {
                const filter = new Filter();
                filter.value = criteria.value;
                filter.name = criteria.name;
                filters.push(filter);
            }
        });
        if (this.sort != null) {
            const sortField = new Filter();
            sortField.name = 'sort';
            sortField.value = this.sort.field;
            filters.push(sortField);

            const sortOrder = new Filter();
            sortOrder.name = 'sortOrder';
            sortOrder.value = this.sort.asc ? 'asc' : 'desc';
            filters.push(sortOrder);
        }
        return filters;
    }

    launchBatch(data: string) {
        this.dispatcher.dispatch(new Action(ActionTypes.LAUNCH_BATCH, data)).toPromise()
            .then(() => {
            });
    }

}
