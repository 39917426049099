import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderModelScreenComponent} from './order-model-screen/order-model-screen.component';
import {FeaturesModule} from "../../features/features.module";
import {ComponentsModule} from "../../components/components.module";
import {SharedModule} from "../../shared/shared.module";


@NgModule({
    declarations: [OrderModelScreenComponent],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class OrderModelModule {
}
