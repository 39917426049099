<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/login'"
        [loading]="loading"
        [page]="page"
        [title]="'Login'"
        [values]="logins"
        (searchItemEmitter)="searchLogin()"
        (sortEventEmitter)="sortLogin($event)"
        (loadItemPageEmitter)="loadLoginPage($event)"
        (launchBatchEmitter)="launchBatch()"
        [detailPage]="true"
        [displayImport]="false"
        [displayLaunchImport]="true"
        [sortData]="true">
</app-list-tab-view>
