import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../../../services/state/state.service";
import {DispatcherService} from "../../../services/dispatcher/dispatcher.service";
import {Action} from "../../../services/dispatcher/action";
import {ActionTypes} from "../../../services/dispatcher/action-types.enum";

@Component({
    selector: 'app-execution-details',
    templateUrl: './execution-details.component.html'
})
export class ExecutionDetailsComponent implements OnInit {

    loading: any;
    title: any;
    value: any;
    data: any;

    constructor(private router: Router,
                private stateService: StateService,
                private dispatcher: DispatcherService) {
    }

    ngOnInit(): void {
        this.data = this.stateService.data;
        if (!this.data) {
            this.router.navigateByUrl('/execution');
        } else {
            this.getExecutionDetail();
        }
    }

    redirection() {
        this.stateService.data = null;
        window.history.back();
    }

    getExecutionDetail() {
        let data: any;
        this.loading = true;
        this.dispatcher.dispatch(new Action(ActionTypes.GET_EXECUTION_DETAIL_BY_ID, this.data)).toPromise()
            .then((action) => {
                data = action.data;
            }).finally(() => {
            this.value = data;
            this.loading = false;
        });
    }

    setTitle() {
        if (this.value) {
            this.title = this.value.status;
        }
    }
}
