<h1 class="title">{{title}} <span *ngIf="page">({{page.totalElements}} éléments)</span></h1>

<div class="export-container">
    <button pButton
            type="button"
            icon="pi pi-"
            *ngIf="displayLaunchImport"
            label="{{ 'export-button' | translate}}"
            class="ui-button-raised delete-button"
            [disabled]="loading"
            (click)="launchBatchEmitter.emit('orders')"></button>

    <button pButton
            type="button"
            icon="pi pi-"
            *ngIf="displayLaunchExport"
            label="{{ 'drafts-export-button' | translate}}"
            class="ui-button-raised delete-button"
            [disabled]="loading"
            (click)="launchBatchEmitter.emit('drafts')"></button>
</div>

<p-tabView>
    <p-tabPanel header="Liste">
        <div class="tab-content-layout">
            <app-lazy-table-criteria *ngIf="filter"
                                     [cols]="criteriaFields"
                                     [loading]="loading"
                                     (searchEvent)="searchItem($event)">
            </app-lazy-table-criteria>

            <app-lazy-table
                    [cols]="cols"
                    [values]="values"
                    [detailsBaseURL]="detailsBaseURL"
                    [nbRows]="pageSize"
                    [totalRows]="pageTotalElements"
                    [loading]="loading"
                    [page]="pageNumber"
                    (sortEvent)="sortItem($event)"
                    (lazyLoading)="loadItemPage($event)"
                    (editEvent)="redirection($event)"
                    [detailPage]="detailPage"
                    (deleteEvent)="deleteItem($event)"
                    [sortData]="sortData">
            </app-lazy-table>
        </div>
    </p-tabPanel>

    <p-tabPanel header="import" *ngIf="displayImport">
        <app-import [actionName]="importName"></app-import>
    </p-tabPanel>
</p-tabView>
