import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeaturesModule} from '../features/features.module';
import {SharedModule} from '../shared/shared.module';
import {HomeScreenComponent} from './home-screen/home-screen.component';
import {ComponentsModule} from '../components/components.module';
import {PricingModule} from "./pricing/pricing.module";
import {ContactModule} from "./contact/contact.module";
import {ReferenceModule} from "./reference/reference.module";
import {OrderModelModule} from "./order-model/order-model.module";
import {CustomerModule} from "./customer/customer.module";
import {LoginModule} from "./login/login.module";
import {SalesHistoryModule} from "./sales-history/sales-history.module";
import {UpdatedOrderModule} from "./updated-order/updated-order.module";
import {OrderModule} from "./order/order.module";
import {ProductModule} from "./product/product.module";
import {SpitBackgroundModule} from "./spit-background/spit-background.module";
import {ExecutionModule} from "./execution/execution.module";

@NgModule({
    declarations: [
        HomeScreenComponent
    ],
    imports: [
        CommonModule,
        FeaturesModule,
        ComponentsModule,
        SharedModule,
        PricingModule,
        ContactModule,
        ReferenceModule,
        OrderModelModule,
        CustomerModule,
        LoginModule,
        SalesHistoryModule,
        UpdatedOrderModule,
        OrderModule,
        ProductModule,
        SpitBackgroundModule,
        ExecutionModule
    ],
    exports: []
})
export class ScreensModule {
}
