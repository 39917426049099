<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/order/detail'"
        [loading]="loading"
        [page]="page"
        (loadItemPageEmitter)="loadOrderLinePage($event)"
        [title]="'Commandes'"
        [values]="orderline"
        [displayImport]="false"
        [detailPage]="true"
        [filter]="false"
        [sortData]="false">
</app-list-tab-view>

<div class="p-col-5 p-md-4 p-lg-2">
    <button pButton
            type="button"
            icon="pi pi-undo"
            label="Retour"
            class="ui-button-raised w-100"
            [disabled]="loading"
            (click)="redirection()">
    </button>
</div>
