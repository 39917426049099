import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeScreenComponent} from './screens/home-screen/home-screen.component';
import {PricingScreenComponent} from "./screens/pricing/pricing-screen/pricing-screen.component";
import {PricingDetailsComponent} from "./screens/pricing/pricing-details/pricing-details.component";
import {ContactScreenComponent} from "./screens/contact/contact-screen/contact-screen.component";
import {OrderModelScreenComponent} from "./screens/order-model/order-model-screen/order-model-screen.component";
import {ReferenceScreenComponent} from "./screens/reference/reference-screen/reference-screen.component";
import {AuthGuard} from "./services/guards/auth.guard";
import {CustomerScreenComponent} from "./screens/customer/customer-screen/customer-screen.component";
import {CustomerDetailsComponent} from "./screens/customer/customer-details/customer-details.component";
import {LoginScreenComponent} from "./screens/login/login-screen/login-screen.component";
import {LoginDetailsComponent} from "./screens/login/login-details/login-details.component";
import {SalesHistoryScreenComponent} from "./screens/sales-history/sales-history-screen/sales-history-screen.component";
import {SalesHistoryDetailsComponent} from "./screens/sales-history/sales-history-details/sales-history-details.component";
import {UpdatedOrderScreenComponent} from "./screens/updated-order/updated-order-screen/updated-order-screen.component";
import {UpdatedOrderDetailsComponent} from "./screens/updated-order/updated-order-details/updated-order-details.component";
import {OrderDetailsComponent} from "./screens/order/order-details/order-details.component";
import {OrderScreenComponent} from "./screens/order/order-screen/order-screen.component";
import {ProductScreenComponent} from "./screens/product/product-screen/product-screen.component";
import {ProductDetailsComponent} from "./screens/product/product-details/product-details.component";
import {SpitBackgroundScreenComponent} from "./screens/spit-background/spit-background-screen/spit-background-screen.component";
import {SpitBackgroundDetailsComponent} from "./screens/spit-background/spit-background-details/spit-background-details.component";
import {Role} from "./@entities/role";
import {ProductKitDetailsComponent} from './screens/product/product-kit-detail/product-kit-details.component';
import {ExecutionScreenComponent} from './screens/execution/execution-screen/execution-screen.component';
import {ExecutionDetailsComponent} from './screens/execution/execution-details/execution-details.component';
import {KitScreenComponent} from "./screens/product/kit-screen/kit-screen.component";

const routes: Routes = [
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: 'contact', component: ContactScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN, Role.IMPORT]}},
    {path: 'home', component: HomeScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN, Role.IMPORT, Role.USER]}},
    {path: 'order-model', component: OrderModelScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.IMPORT, Role.ADMIN]}},
    {path: 'reference', component: ReferenceScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.IMPORT, Role.ADMIN]}},
    {path: 'pricing', component: PricingScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'pricing/detail', component: PricingDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'customer', component: CustomerScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'customer/detail', component: CustomerDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'login', component: LoginScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'login/detail', component: LoginDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'product', component: ProductScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'product/detail', component: ProductDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'product/detail/detail', component: ProductKitDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'order', component: OrderScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'order/detail', component: OrderDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'sales-history', component: SalesHistoryScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'sales-history/detail', component: SalesHistoryDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'updated-orders', component: UpdatedOrderScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'updated-orders/detail', component: UpdatedOrderDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'product/kit', component: KitScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'spit-background', component: SpitBackgroundScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'spit-background/detail', component: SpitBackgroundDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.ADMIN]}},
    {path: 'execution', component: ExecutionScreenComponent, canActivate: [AuthGuard], data: {roles: [Role.IMPORT, Role.ADMIN]}},
    {path: 'execution/detail', component: ExecutionDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.IMPORT, Role.ADMIN]}}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
