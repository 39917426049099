import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExecutionScreenComponent} from './execution-screen/execution-screen.component';
import {SharedModule} from '../../shared/shared.module';
import {FeaturesModule} from '../../features/features.module';
import {ComponentsModule} from '../../components/components.module';
import {ExecutionDetailsComponent} from './execution-details/execution-details.component';


@NgModule({
    declarations: [ExecutionScreenComponent, ExecutionDetailsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class ExecutionModule {
}
