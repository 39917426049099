import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {FeaturesModule} from '../../features/features.module';
import {ComponentsModule} from '../../components/components.module';
import {LoginScreenComponent} from './login-screen/login-screen.component';
import { LoginDetailsComponent } from './login-details/login-details.component';

@NgModule({
    declarations: [
        LoginScreenComponent,
        LoginDetailsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class LoginModule {
}
