import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StateService} from '../../../../services/state/state.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';

@Component({
    selector: 'app-lazy-table',
    templateUrl: './lazy-table.component.html',
    styleUrls: ['./lazy-table.component.scss'],
    providers: [ConfirmationService]
})
export class LazyTableComponent implements OnInit {
    @Input() values = [];
    @Input() cols = [];
    @Input() detailsBaseURL = '';
    @Input() nbRows = 25;
    @Input() totalRows = 10;
    pageNumber: number;

    @Input() set page(value: number) {
        this.pageNumber = value;
        this.initializePageNumber();
    }

    get page(): number {
        return this.pageNumber;
    }

    first: number;
    @Input() loading = false;
    @Output() lazyLoading = new EventEmitter<{ rows: number, first: number }>();
    @Output() sortEvent = new EventEmitter<{ field: string, asc: boolean }>();
    @Output() editEvent = new EventEmitter<number>();
    @Output() deleteEvent = new EventEmitter<{ data: any, number: string }>();
    @Input() detailPage: boolean;
    @Input() sortData: boolean;
    selectedItem: any;
    items: MenuItem[];
    queryParamsList: Params = {};
    sortOrder: number;
    sortField: string;

    constructor(private router: Router,
                private stateService: StateService,
                private activatedRoute: ActivatedRoute,
                private confirmationService: ConfirmationService) {
        this.sortOrder = 1;
        this.sortField = null;
    }

    ngOnInit() {
        const commandDelete = (onclick) => {
            this.delete();
        };
        const commandDeleteAll = (onclick) => {
            this.deleteAll();
        };
        this.items = [
            {label: 'Supprimer', icon: 'pi pi-trash', command: commandDelete},
            {label: 'Tout supprimer', icon: 'pi pi-trash', command: commandDeleteAll}
        ];
    }

    initializePageNumber(): void {
        this.pageNumber = 0;
        this.queryParamsList = {};
        let addSorting = false;
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.pageNumber) {
                this.pageNumber = param.pageNumber;
                this.queryParamsList.pageNumber = this.pageNumber;
            }
            this.cols.forEach(element => {
                if (param[element.field] && param[element.field] !== '') {
                    this.queryParamsList[element.field] = param[element.field];
                }
            });
            if (param.sort) {
                this.queryParamsList.sort = param.sort;
                this.queryParamsList.sortOrder = param.sortOrder;
                addSorting = true;
            }
        });
        this.router.navigate([],
            {
                relativeTo: this.activatedRoute,
                queryParams: this.queryParamsList
            });
        this.first = this.pageNumber * this.nbRows;
        if (addSorting) {
            this.sortField = this.queryParamsList.sort;
            this.sortOrder = this.queryParamsList.sortOrder === 'asc' ? 1 : -1;
            const sortOrder = this.queryParamsList.sortOrder === 'asc';
            this.sortEvent.emit({field: this.queryParamsList.sort, asc: sortOrder});
        }
        if (this.first as number) {
            this.lazyLoading.emit({rows: this.nbRows, first: this.first});
        }
    }

    callLazyLoading(event: LazyLoadEvent) {
        if (!(event.first as number)) {
            event.first = 0;
        }
        this.lazyLoading.emit({rows: event.rows, first: event.first});
    }

    setSelectedItem(value: any) {
        this.selectedItem = value;
    }

    edit() {
        this.editEvent.emit(this.selectedItem);
    }

    delete() {
        this.confirmationService.confirm({
                message: "Etes vous sûre de vouloir supprimer cet élément ?",
                header: "Confirmation de suppression",
                accept: () => {
                    this.deleteEvent.emit({data: this.selectedItem, number: 'one'});
                }
            }
        );
    }

    deleteAll() {
        this.confirmationService.confirm({
                message: "Etes vous sûre de vouloir supprimer cet élément ainsi que les éléments qui lui sont associés ?",
                header: "Confirmation de suppression",
                accept: () => {
                    this.deleteEvent.emit({data: this.selectedItem, number: 'all'});
                }
            }
        );
    }

    onGoToDetails(value) {
        const queryParamsList: Params = {};
        this.activatedRoute.queryParams.subscribe(param => {
            this.cols.forEach(element => {
                if (param[element.field] && param[element.field] !== '') {
                    queryParamsList[element.field] = param[element.field];
                }
            });
            if (param.sort) {
                queryParamsList.sort = param.sort;
                queryParamsList.sortOrder = param.sortOrder;
            }
            if (param.pageNumber) {
                queryParamsList.pageNumber = param.pageNumber;
            }
        });
        this.router.navigate([],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
        if (this.detailPage) {
            this.stateService.data = value;
            this.router.navigateByUrl(this.detailsBaseURL + '/detail');
        }
    }

    onSort(event: { field: string, order: number }) {
        const queryParamsList: Params = {};
        this.activatedRoute.queryParams.subscribe(param => {
            this.cols.forEach(element => {
                if (param[element.field] && param[element.field] !== '') {
                    queryParamsList[element.field] = param[element.field];
                }
            });
        });
        queryParamsList.sort = event.field;
        queryParamsList.sortOrder = event.order === 1 ? 'asc' : 'desc';
        this.router.navigate([],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
        this.sortEvent.emit({field: event.field, asc: event.order === 1});
    }

    onPageChange($event: any) {
        const queryParamsList: Params = {};
        this.activatedRoute.queryParams.subscribe(param => {
            this.cols.forEach(element => {
                if (param[element.field] && param[element.field] !== '') {
                    queryParamsList[element.field] = param[element.field];
                }
            });
            if (param.sort) {
                queryParamsList.sort = param.sort;
                queryParamsList.sortOrder = param.sortOrder ? 'asc' : 'desc';
            }
        });
        queryParamsList.pageNumber = $event.first / this.nbRows;
        this.router.navigate([],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }
}
