import {Component, OnInit} from '@angular/core';
import {Page} from '../../../@entities/Pageable/page';
import {DispatcherService} from '../../../services/dispatcher/dispatcher.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Action} from '../../../services/dispatcher/action';
import {ActionTypes} from '../../../services/dispatcher/action-types.enum';
import {Filter} from '../../../@entities/Pageable/filter';
import {ILogin} from '../../../@entities/ilogin';

@Component({
    selector: 'app-login-screen',
    templateUrl: './login-screen.component.html'
})
export class LoginScreenComponent implements OnInit {

    loading = false;
    defaultListSize = 25;
    logins: ILogin[];
    page: Page<ILogin> = new Page();
    sort: { field: string, asc: boolean };
    cols = [
        {header: 'Login', field: 'login'},
        {header: 'Code vendeur', field: 'sellerCode'}
    ];

    criteriaFields = [
        {name: 'login', placeHolder: 'Login', type: 'input', value: ''},
        {name: 'sellerCode', placeHolder: 'Code vendeur', type: 'input', value: ''},
    ];
    private selectedLogin: ILogin;

    constructor(
        private dispatcher: DispatcherService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
    }

    getLogin() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_ALL_LOGIN, this.page)).toPromise()
            .then((action) => {
                this.page = action.data;
                if (!this.page) {
                    this.selectedLogin = null;
                    this.logins = null;
                } else {
                    this.selectedLogin = this.page.content[0];
                    this.logins = this.page.content;
                }
            }).finally(() => {
            this.loading = false;
        });
    }

    public searchLogin() {
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
        this.getLogin();
    }

    public sortLogin(event: { field: string, asc: boolean }) {
        this.sort = {field: event.field, asc: event.asc};
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParamsList: Params = {};
        this.criteriaFields.forEach(criteria => {
            if (criteria.type === 'input') {
                if (criteria.value && criteria.value !== '') {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'select') {
                if (criteria.value && criteria.value.length !== 0) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            }
        });
        if (this.sort && this.sort.field !== null) {
            queryParamsList.sort = this.sort.field;
            queryParamsList.sortOrder = this.sort.asc ? 'asc' : 'desc';
        }
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.pageNumber) {
                queryParamsList.pageNumber = param.pageNumber;
            }
        });
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }


    public loadLoginPage(event: { rows: number, first: number }) {
        this.page.number = (event.first !== 0 && event.rows !== undefined) ? (event.first / event.rows) : 0;
        this.page.size = this.defaultListSize;
        this.getLogin();
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        this.criteriaFields.forEach(criteria => {
            if (criteria.value !== null) {
                const filter = new Filter();
                filter.name = criteria.name;
                filter.value = criteria.value;
                filters.push(filter);
            }
        });
        if (this.sort != null) {
            const sortField = new Filter();
            sortField.name = "sort";
            sortField.value = this.sort.field;
            filters.push(sortField);

            const sortOrder = new Filter();
            sortOrder.name = "sortOrder";
            sortOrder.value = this.sort.asc ? 'asc' : 'desc';
            filters.push(sortOrder);
        }
        return filters;
    }

    launchBatch() {
        this.dispatcher.dispatch(new Action(ActionTypes.LAUNCH_BATCH, 'login')).toPromise()
            .then(() => {
            });
    }

}
