import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from "../../shared/shared.module";
import {FeaturesModule} from "../../features/features.module";
import {ComponentsModule} from "../../components/components.module";
import {SpitBackgroundScreenComponent} from "./spit-background-screen/spit-background-screen.component";
import {SpitBackgroundDetailsComponent} from "./spit-background-details/spit-background-details.component";


@NgModule({
    declarations: [SpitBackgroundScreenComponent, SpitBackgroundDetailsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class SpitBackgroundModule {
}
