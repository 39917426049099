import {EventEmitter, Injectable} from '@angular/core';
import {IActionDispatcher} from 'src/app/@entities/iaction-dispatcher';
import {IAction} from 'src/app/@entities/iaction';
import {ActionTypes} from './action-types.enum';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {MessageService} from 'primeng/api';
import {PricingManagerService} from '../manager/pricing/pricing-manager.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ContactManagerService} from '../manager/contact/contact-manager.service';
import {OrderModelManagerService} from '../manager/order-model/order-model-manager.service';
import {ReferenceManagerService} from '../manager/reference/reference-manager.service';
import {ImportManagerService} from '../manager/import/import-manager.service';
import {CustomerManagerService} from '../manager/customer/customer-manager.service';
import {LoginManagerService} from "../manager/login/login-manager.service";
import {SalesHistoryManagerService} from "../manager/sales-history/sales-history-manager.service";
import {UpdatedOrderManagerService} from "../manager/updated-order/updated-order-manager.service";
import {OrderManagerService} from "../manager/order/order-manager.service";
import {ProductManagerService} from "../manager/product/product-manager.service";
import {OrderLineManagerService} from "../manager/order-line/order-line-manager.service";
import {SpitBackgroundManagerService} from "../manager/spit-background/spit-background-manager.service";
import {ExecutionManagerService} from "../manager/execution/execution-manager.service";

@Injectable({
    providedIn: 'root'
})
export class DispatcherService implements IActionDispatcher {

    public notifier = new EventEmitter();

    constructor(
        private messageService: MessageService,
        private pricingManagerService: PricingManagerService,
        private contactManagerService: ContactManagerService,
        private orderModelManagerService: OrderModelManagerService,
        private referenceManagerService: ReferenceManagerService,
        private importManagerService: ImportManagerService,
        private customerManagerService: CustomerManagerService,
        private loginManagerService: LoginManagerService,
        private salesHistoryManagerService: SalesHistoryManagerService,
        private updatedOrderManagerService: UpdatedOrderManagerService,
        private orderManagerService: OrderManagerService,
        private productManagerService: ProductManagerService,
        private orderLineManagerService: OrderLineManagerService,
        private spitBackgroundManagerService: SpitBackgroundManagerService,
        private executionManagerService: ExecutionManagerService
    ) {
    }

    dispatch(action: IAction): Observable<IAction> {
        switch (action.type) {
            case ActionTypes.DELETE_ONE_CONTACT:
                return this.contactManagerService.deleteOneContact(action.data).pipe(
                    map(data => {
                        action.data = data;
                        if (!action.data) {
                            this.addErrorToMessageNoContent('Suppresion d\'un contact');
                        }
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Suppresion de contacts');
                        return of(undefined);
                    })
                );
            case ActionTypes.DELETE_ONE_ORDER_MODEL:
                return this.orderModelManagerService.deleteOneOrderModel(action.data).pipe(
                    map(data => {
                        action.data = data;
                        if (!action.data) {
                            this.addErrorToMessageNoContent('Suppresion d\'un modele de commande');
                        }
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Suppresion d\'un modele de commande');
                        return of(undefined);
                    })
                );
            case ActionTypes.DELETE_ONE_REFERENCE:
                return this.referenceManagerService.deleteOneReference(action.data).pipe(
                    map(data => {
                        action.data = data;
                        if (!action.data) {
                            this.addErrorToMessageNoContent('Suppresion d\'une référence');
                        }
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Suppresion d\'une référence');
                        return of(undefined);
                    })
                );
            case ActionTypes.DELETE_ALL_CONTACT_WITH_CUSTOMER_NUMBER:
                return this.contactManagerService.deleteAllContactWithCustomerNumber(action.data).pipe(
                    map(data => {
                        action.data = data;
                        if (!action.data) {
                            this.addErrorToMessageNoContent('Suppresion de contacts');
                        }
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Suppresion de contacts');
                        return of(undefined);
                    })
                );
            case ActionTypes.DELETE_ALL_REFERENCE_WITH_NAME:
                return this.referenceManagerService.deleteAllReferenceWithName(action.data).pipe(
                    map(data => {
                        action.data = data;
                        if (!action.data) {
                            this.addErrorToMessageNoContent('Suppresion de referencement');
                        }
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Suppresion de referencement');
                        return of(undefined);
                    })
                );
            case ActionTypes.DELETE_ALL_ORDER_MODEL_WITH_NAME:
                return this.orderModelManagerService.deleteAllOrderModelWithName(action.data).pipe(
                    map(data => {
                        action.data = data;
                        if (!action.data) {
                            this.addErrorToMessageNoContent('Suppresion de modèle de commande');
                        }
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Suppresion de modèle de commande');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ALL_SPIT_BACKGROUND:
                return this.spitBackgroundManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Fond de broches');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ALL_ORDER:
                return this.orderManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Commandes');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ORDER_LINE_BY_ID:
                return this.orderLineManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Ligne de commande');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ALL_PRODUCTS:
                return this.productManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Articles');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ALL_SALES_HISTORY:
                return this.salesHistoryManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Historiques des ventes');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ALL_EXECUTION:
                return this.executionManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Executions');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_EXECUTION_DETAIL_BY_ID:
                return this.executionManagerService.readByPageExecutionDetail(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Executions');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ALL_PRICING:
                return this.pricingManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Tarification');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ALL_CUSTOMER:
                return this.customerManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Clients');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ALL_UPDATED_ORDER:
                return this.updatedOrderManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Mise à jour des commandes');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ALL_LOGIN:
                return this.loginManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Logins');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_CONTACTS:
                return this.contactManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Contact');
                        return of(undefined);
                    })
                );
            case ActionTypes.IMPORT_CONTACT:
                return this.contactManagerService.import(action.data).pipe(
                    map(data => {
                        action.data = data;
                        this.addSuccessToMessage('Import Contact', 'L\'import a bien été effectué : ' + data);
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Import Contact');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_ORDER_MODELS:
                return this.orderModelManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Modèle de commande');
                        return of(undefined);
                    })
                );
            case ActionTypes.IMPORT_ORDER_MODEL:
                return this.orderModelManagerService.import(action.data).pipe(
                    map(data => {
                        action.data = data;
                        this.addSuccessToMessage('Import Modèle de commande', 'L\'import a bien été effectué : ' + data);
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Import Modèle de commande');
                        return of(undefined);
                    })
                );
            case ActionTypes.GET_REFERENCES:
                return this.referenceManagerService.readByPage(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Référencement');
                        return of(undefined);
                    })
                );
            case ActionTypes.IMPORT_REFERENCE:
                return this.referenceManagerService.import(action.data).pipe(
                    map(data => {
                        action.data = data;
                        this.addSuccessToMessage('Import Référencement', 'L\'import a bien été effectué : ' + data);
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Import Référencement');
                        return of(undefined);
                    })
                );
            case ActionTypes.IMPORT_STATUS:
                return this.importManagerService.read(action.data).pipe(
                    map(data => {
                        action.data = data;
                        return action;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Impossible de lire le statut de l\'import');
                        return of(undefined);
                    })
                );
            case ActionTypes.LAUNCH_BATCH:
                return this.importManagerService.launchBatch(action.data).pipe(
                    map(data => {
                        this.addSuccessToMessage('Succès','Le batch a bien été lancé : ' + data);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.addErrorToMessage(error, 'Impossible de lancer le batch');
                        return of(undefined);
                    })
                )
            default:
                console.error(action);
                throw Error('Invalid action type');
        }
    }

    addErrorToMessage(error: HttpErrorResponse, name: string) {
        this.messageService.add({
            severity: 'error',
            summary: 'Message ' + name,
            detail: 'Something went wrong... \ntechnical details : ' +
                (error.error !== undefined ? (error.error.error !== undefined ? error.error.error : error.error) : error),
            life: 5000,
        });
    }

    addSuccessToMessage(name: string, detail: string) {
        this.messageService.add({
            severity: 'success',
            summary: 'Message ' + name,
            detail,
            life: 5000,
        });
    }

    addErrorToMessageNoContent(name: string) {
        this.messageService.add({
            severity: 'error',
            summary: 'Message ' + name,
            detail: 'Something went wrong... \ntechnical details : no data to delete',
            life: 5000,
        });
    }
}
