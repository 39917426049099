import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {StorageService} from '../../storage/storage.service';
import {Observable} from 'rxjs';
import {Page} from '../../../@entities/Pageable/page';
import {HttpParams} from '@angular/common/http';
import {ISalesHistory} from '../../../@entities/isales-history';

@Injectable({
    providedIn: 'root'
})
export class SalesHistoryManagerService {

    api = environment.apiUrl + environment.resourceUri.salesHistory;

    constructor(private storageService: StorageService) {
    }

    read(id?: number): Observable<ISalesHistory>;
    read(id?: any): Observable<ISalesHistory> | Observable<ISalesHistory[]> {
        return this.storageService.read(this.api + '/query/' + id);
    }

    readByPage(page: Page<ISalesHistory>): Observable<Page<ISalesHistory>> {
        return this.storageService.readWithParams(this.api + '/query/list', this.createParams(page));
    }

    private createParams(page: Page<ISalesHistory>) {
        let params = new HttpParams();

        if (page.number || page.number === 0) {
            params = params.set('pageNumber', page.number.toString());
        }
        if (page.size) {
            params = params.set('pageSize', page.size.toString());
        }
        if (page.filter) {
            page.filter.forEach(filter => {
                if (filter.value !== '') {
                    params = params.set(filter.name, filter.value);
                }
            });
        }
        return params;
    }
}
