<div class="p-grid">
    <div class="p-col-5 p-md-4 p-lg-2 criteria-element" *ngFor="let field of columns" [ngSwitch]="field.type">
        <input
                *ngSwitchCase="'input'"
                (keyup.enter)="search()"
                pInputText
                class="ui-inputtext ui-corner-all w-100 h-100"
                type="text"
                [(ngModel)]="field.value"
                placeholder="{{field.placeHolder | translate}}"
                [disabled]="loading"/>
        <span *ngSwitchCase="'select'">{{field.placeHolder | translate}} :</span>
        <p-dropdown
                *ngSwitchCase="'select'"
                [options]="field.values"
                [(ngModel)]="field.value"
                (onChange)="changeSelect()"
                defaultLabel="{{field.placeHolder | translate}}"
                class="w-100 h-100"
                [disabled]="loading">
        </p-dropdown>
        <ng-container *ngSwitchCase="'checkbox'">
            <p-triStateCheckbox class="checkbox" [(ngModel)]="field.value"
                                label="{{field.placeHolder | translate}}"></p-triStateCheckbox>
        </ng-container>

    </div>
    <div class="p-col-5 p-md-4 p-lg-2 criteria-element">
        <button pButton
                type="button"
                icon="pi pi-search"
                label="Rechercher"
                class="ui-button-raised w-100 search-button"
                [disabled]="loading"
                (click)="search()">
        </button>
    </div>
    <div class="p-col-5 p-md-4 p-lg-2 criteria-element">
        <button pButton
                type="button"
                icon="pi pi-trash"
                label="Vider les filtres"
                class="ui-button-raised w-100 delete-button"
                [disabled]="loading"
                (click)="deleteFilters()">
        </button>
    </div>
</div>
