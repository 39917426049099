<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/reference-screen'"
        [loading]="loading"
        [page]="page"
        [title]="'Référencements'"
        [values]="reference"
        (searchItemEmitter)="searchReference()"
        (sortEventEmitter)="sortReference($event)"
        (loadItemPageEmitter)="loadReferencePage($event)"
        [displayImport]="true"
        [importName]="'reference'"
        [detailPage]="false"
        (deleteItemEmitter)="deleteReference($event)"
        [sortData]="true">
</app-list-tab-view>
