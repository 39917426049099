import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StateService} from '../../../services/state/state.service';
import {ILogin} from '../../../@entities/ilogin';

@Component({
    selector: 'app-login-details',
    templateUrl: './login-details.component.html'
})
export class LoginDetailsComponent implements OnInit {

    loading: any;
    title: any;
    value: ILogin;

    constructor(
        private router: Router,
        private stateService: StateService
    ) {
    }

    ngOnInit(): void {
        this.value = this.stateService.data;
        if (!this.value) {
            this.router.navigateByUrl('/login');
        }
    }

    setTitle() {
        if (this.value) {
            this.title = this.value.login;
        }
    }

}
