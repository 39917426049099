import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DetailTabViewComponent} from "./detail-tab-view/detail-tab-view.component";
import {ListTabViewComponent} from "./list-tab-view/list-tab-view.component";
import {SharedModule} from "../../shared/shared.module";
import {ComponentsModule} from "../../components/components.module";


@NgModule({
    declarations: [
        DetailTabViewComponent,
        ListTabViewComponent],
    imports: [
        CommonModule,
        SharedModule,
        ComponentsModule
    ],
    exports: [
        DetailTabViewComponent,
        ListTabViewComponent
    ]
})
export class MyTabViewModule {
}
