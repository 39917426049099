import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from "../../shared/shared.module";
import {FeaturesModule} from "../../features/features.module";
import {ComponentsModule} from "../../components/components.module";
import { OrderScreenComponent } from './order-screen/order-screen.component';
import { OrderDetailsComponent } from './order-details/order-details.component';


@NgModule({
    declarations: [OrderScreenComponent, OrderDetailsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class OrderModule {
}
