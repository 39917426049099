import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerScreenComponent} from './customer-screen/customer-screen.component';
import {SharedModule} from '../../shared/shared.module';
import {FeaturesModule} from '../../features/features.module';
import {ComponentsModule} from '../../components/components.module';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';


@NgModule({
    declarations: [
        CustomerScreenComponent,
        CustomerDetailsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class CustomerModule {
}
