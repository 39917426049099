import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SalesHistoryScreenComponent} from './sales-history-screen/sales-history-screen.component';
import {SharedModule} from "../../shared/shared.module";
import {FeaturesModule} from "../../features/features.module";
import {ComponentsModule} from "../../components/components.module";
import { SalesHistoryDetailsComponent } from './sales-history-details/sales-history-details.component';


@NgModule({
    declarations: [SalesHistoryScreenComponent, SalesHistoryDetailsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class SalesHistoryModule {
}
