import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {RowDataView} from "../../../@entities/row-data-view";

@Component({
    selector: 'app-detail-data-view',
    templateUrl: './detail-data-view.component.html',
    styleUrls: ['./detail-data-view.component.scss']
})
export class DetailDataViewComponent implements OnInit, OnChanges {
    @Input() loading;
    @Input() value: any;
    rows: RowDataView[];
    rowsToPrint: RowDataView[];
    selectedRows: RowDataView[];
    isSearched = false;
    isSortAscending = true;

    constructor() {
    }

    ngOnInit(): void {
    }

    buildRows() {
        this.rows = [];
        Object.getOwnPropertyNames(this.value).forEach(property => {
            this.rows.push({label: property, value: this.value[property]});
        });
        this.rowsToPrint = this.rows;
        this.isSortAscending = false;
        this.onSort();
    }

    search() {
        this.rowsToPrint = [];
        if (this.selectedRows && this.selectedRows.length > 0) {
            this.isSearched = true;
            for (let selectedRow of this.selectedRows) {
                const row = this.rows.find(rowToSearch => !rowToSearch.label.localeCompare(selectedRow.label));
                if (row) {
                    this.rowsToPrint.push(row);
                }
            }
        } else {
            this.cancelSearch();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.value) {
            this.buildRows();
        }
    }

    cancelSearch() {
        this.isSearched = false;
        this.rowsToPrint = this.rows;
        this.selectedRows = [];
    }

    onSort() {
        this.rowsToPrint.sort((a, b) => {
            let sortValue = a.label.localeCompare(b.label);
            if (!this.isSortAscending) {
                return sortValue;
            } else {
                return -sortValue;
            }
        });
        this.isSortAscending = !this.isSortAscending;
    }
}
