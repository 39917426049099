<div class="wrapper">
    <div class="overlay" *ngIf="loading">
    </div>

    <div class="loaded-content" [class.blurred]="loading">
        <p-fileUpload
            #fileUpload
            name="myfile[]"
            accept=".csv"
            customUpload="true"
            chooseLabel="Sélectionner"
            uploadLabel="Importer"
            cancelLabel="Annuler"
            (onBeforeUpload)="loading=true"
            (onUpload)="loading=false"
            (uploadHandler)="myUploader($event, fileUpload)">
            <ng-template pTemplate="content">
                <ul *ngIf="uploadedFiles">
                    <li *ngFor="let file of uploadedFiles">
                        {{file.name}} <button type=button pButton icon="pi pi-check" class="ui-button-success" (click)="getStatus(file)" label="Voir le statut"></button>
                    </li>
                </ul>
            </ng-template>
        </p-fileUpload>
        <p-dialog *ngIf="importStatus"
                  header="Statut"
                  [(visible)]="displayModal"
                  [modal]="true"
                  [style]="{width: '50vw'}"
                  [baseZIndex]="10000"
                  [draggable]="false"
                  [resizable]="false">
            <ul>
                <li><strong>Statut</strong> : {{importStatus.status}}</li>
                <li><strong>Statut final</strong> : {{importStatus.exitCode}}</li>
                <li><strong>Début</strong> : {{importStatus.startTime}}</li>
                <li><strong>Fin</strong> : {{importStatus.endTime}}</li>
            </ul>
            <p-accordion *ngIf="importStatus.exitDescription">
                <p-accordionTab header="Erreur">
                    {{importStatus.exitDescription}}
                </p-accordionTab>
            </p-accordion>
            <p-footer>
                <button type="button" pButton icon="pi pi-check" (click)="displayModal=false" label="Ok"></button>
            </p-footer>
        </p-dialog>
    </div>
</div>
