import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../../../services/state/state.service";
import {ISpitBackground} from "../../../@entities/i-spit-background";

@Component({
    selector: 'app-spit-background-details',
    templateUrl: './spit-background-details.component.html'
})
export class SpitBackgroundDetailsComponent implements OnInit {

    loading: any;
    title: any;
    value: ISpitBackground;

    constructor(
        private router: Router,
        private stateService: StateService
    ) {
    }

    ngOnInit(): void {
        this.value = this.stateService.data;
        if (!this.value) {
            this.router.navigateByUrl('/spit-background');
        }
    }

    setTitle() {
        if (this.value) {
            this.title = this.value.customer;
        }
    }
}
