import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {StorageService} from "../../storage/storage.service";
import {Observable} from "rxjs";
import {Page} from "../../../@entities/Pageable/page";
import {HttpParams} from "@angular/common/http";
import {IExecution} from "../../../@entities/iexecution";

@Injectable({
    providedIn: 'root'
})
export class ExecutionManagerService {

    api = environment.apiUrl + environment.resourceUri.execution;

    constructor(private storageService: StorageService) {
    }

    read(id?: number): Observable<IExecution>;
    read(id?: any): Observable<IExecution> | Observable<IExecution[]> {
        return this.storageService.read(this.api + '/query/' + id);
    }

    readByPage(page: Page<IExecution>): Observable<Page<IExecution>> {
        return this.storageService.readWithParams(this.api + '/query/list', this.createParams(page));
    }

    private createParams(page: Page<IExecution>) {
        let params = new HttpParams();

        if (page.number || page.number === 0) {
            params = params.set('pageNumber', page.number.toString());
        }
        if (page.size) {
            params = params.set('pageSize', page.size.toString());
        }
        if (page.filter) {
            page.filter.forEach(filter => {
                if (filter.value !== '') {
                    params = params.set(filter.name, filter.value);
                }
            });
        }
        return params;
    }

    readByPageExecutionDetail(data: IExecution): Observable<any> {
        const api = environment.apiUrl + environment.resourceUri.executionDetail;
        return this.storageService.read(api + '/' + String(data.executionId));
    }
}
