import {Component, Input, OnInit} from '@angular/core';
import {StateService} from "../../../services/state/state.service";

@Component({
    selector: 'app-detail-tab-view',
    templateUrl: './detail-tab-view.component.html',
    styleUrls: ['../my-tab-view.scss']
})
export class DetailTabViewComponent implements OnInit {
    @Input() loading: boolean;
    @Input() title: string;
    @Input() displayRow = false;
    @Input() value;

    constructor(private stateService: StateService) {
    }

    ngOnInit(): void {
    }

    redirection() {
        this.stateService.data = null;
        window.history.back();
    }
}
