import {Component, OnInit} from '@angular/core';
import {IProduct} from "../../../@entities/iproduct";
import {Page} from "../../../@entities/Pageable/page";
import {DispatcherService} from "../../../services/dispatcher/dispatcher.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Action} from "../../../services/dispatcher/action";
import {ActionTypes} from "../../../services/dispatcher/action-types.enum";
import {Filter} from "../../../@entities/Pageable/filter";

@Component({
    selector: 'app-product-screen',
    templateUrl: './product-screen.component.html'
})
export class ProductScreenComponent implements OnInit {

    loading = false;
    defaultListSize = 25;
    product: IProduct[];
    page: Page<IProduct> = new Page();
    sort: { field: string, asc: boolean };
    cols = [
        {header: 'Identifiant', field: 'productId'},
        {header: 'Description', field: 'description'},
        {header: 'Article de substitution', field: 'substituteProductId'},
        {header: 'Code EAN', field: 'eanCode'},
        {header: 'Code famille', field: 'familyCode'},
        {header: 'Description famille', field: 'familyDesc'},
        {header: 'Code grande famille', field: 'bigFamilyCode'},
        {header: 'Description grande famille', field: 'bigFamilyDescription'},
        {header: 'Code magasin d\'usine', field: 'shopFactoryCode'},
        {header: 'Description magasin d\'usine', field: 'shopFactoryDescription'},
        {header: 'Code marque', field: 'brandCode'},
        {header: 'Description marque', field: 'brandDescription'},
        {header: 'Mois de semis', field: 'sowingMonth'},
        {header: 'Ordre Alpha', field: 'alphaOrder'},
        {header: 'Par combien', field: 'pcb'},
        {header: 'Rupture jusqu\'au', field: 'temporarySoldOutDate'},
        {header: 'Code segment', field: 'segmentCode'},
        {header: 'Description segment', field: 'segmentDescription'},
        {header: 'Code sous segment', field: 'subSegmentCode'},
        {header: 'Description sous segment', field: 'subSegmentDescription'},
        {header: 'Thème', field: 'theme'},
        {header: 'Destination', field: 'destination'},
        {header: 'Code série de prix', field: 'priceSetCode'},
        {header: 'Description série de prix', field: 'priceSetDescription'},
        {header: 'Nom', field: 'name'},
        {header: 'Status', field: 'status'},
        {header: 'Groupe Tarif Article', field: 'priceGroup'},
        {header: 'Kit', field: 'kit'}
    ];

    criteriaFields = [
        {name: 'productId', placeHolder: 'Identifiant', type: 'input', value: ''},
        {name: 'description', placeHolder: 'Description', type: 'input', value: ''},
        {name: 'substituteProductId', placeHolder: 'Article de substitution', type: 'input', value: ''},
        {name: 'eanCode', placeHolder: 'Code EAN', type: 'input', value: ''},
        {name: 'familyCode', placeHolder: 'Code famille', type: 'input', value: ''},
        {name: 'familyDesc', placeHolder: 'Description famille', type: 'input', value: ''},
        {name: 'bigFamilyCode', placeHolder: 'Code grande famille', type: 'input', value: ''},
        {name: 'bigFamilyDescription', placeHolder: 'Description grande famille', type: 'input', value: ''},
        {name: 'shopFactoryCode', placeHolder: 'Code magasin d\'usine', type: 'input', value: ''},
        {name: 'shopFactoryDescription', placeHolder: 'Description magasin d\'usine', type: 'input', value: ''},
        {name: 'brandCode', placeHolder: 'Code marque', type: 'input', value: ''},
        {name: 'brandDescription', placeHolder: 'Description marque', type: 'input', value: ''},
        {name: 'sowingMonth', placeHolder: 'Mois de semis', type: 'input', value: ''},
        {name: 'alphaOrder', placeHolder: 'Ordre Alpha', type: 'input', value: ''},
        {name: 'pcb', placeHolder: 'Par combien', type: 'input', value: ''},
        {name: 'temporarySoldOutDate', placeHolder: 'Rupture jusqu\'au', type: 'input', value: ''},
        {name: 'segmentCode', placeHolder: 'Code segment', type: 'input', value: ''},
        {name: 'segmentDescription', placeHolder: 'Description segment', type: 'input', value: ''},
        {name: 'subSegmentCode', placeHolder: 'Code sous segment', type: 'input', value: ''},
        {name: 'segmentDescription', placeHolder: 'Description sous segment', type: 'input', value: ''},
        {name: 'theme', placeHolder: 'Thème', type: 'input', value: ''},
        {name: 'destination', placeHolder: 'Destination', type: 'input', value: ''},
        {name: 'priceSetCode', placeHolder: 'Code série de prix', type: 'input', value: ''},
        {name: 'priceSetDescription', placeHolder: 'Description série de prix', type: 'input', value: ''},
        {name: 'name', placeHolder: 'Nom', type: 'input', value: ''},
        {name: 'status', placeHolder: 'Statut', type: 'input', value: ''},
        {name: 'priceGroup', placeHolder: 'Groupe Tarif Article', type: 'input', value: ''},
        {name: 'kit', placeHolder: 'Kit', type: 'checkbox', value: null}
    ];
    private selectedProduct: IProduct;

    constructor(
        private dispatcher: DispatcherService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
    }

    getProduct() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_ALL_PRODUCTS, this.page)).toPromise()
            .then((action) => {
                this.page = action.data;
                if (!this.page) {
                    this.selectedProduct = null;
                    this.product = null;
                } else {
                    this.selectedProduct = this.page.content[0];
                    this.product = this.page.content;
                }
            }).finally(() => {
            this.loading = false;
        });
    }

    public searchProduct() {
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.getProduct();
        this.setQueryParams();
    }

    public sortProduct(event: { field: string, asc: boolean }) {
        this.sort = {field: event.field, asc: event.asc};
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParamsList: Params = {};
        this.criteriaFields.forEach(criteria => {
            if (criteria.type === 'input') {
                if (criteria.value && criteria.value !== '') {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'select') {
                if (criteria.value && criteria.value.length !== 0) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'checkbox') {
                if (criteria.value !== null) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            }
        });
        if (this.sort && this.sort.field !== null) {
            queryParamsList.sort = this.sort.field;
            queryParamsList.sortOrder = this.sort.asc ? 'asc' : 'desc';
        }
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.pageNumber) {
                queryParamsList.pageNumber = param.pageNumber;
            }
        });
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }


    public loadProductPage(event: { rows: number, first: number }) {
        this.page.number = (event.first !== 0 && event.rows !== undefined) ? (event.first / event.rows) : 0;
        this.page.size = this.defaultListSize;
        this.getProduct();
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        this.criteriaFields.forEach(criteria => {
            if (criteria.value !== null) {
                const filter = new Filter();
                filter.name = criteria.name;
                filter.value = criteria.value;
                filters.push(filter);
            }
        });
        if (this.sort != null) {
            const sortField = new Filter();
            sortField.name = "sort";
            sortField.value = this.sort.field;
            filters.push(sortField);

            const sortOrder = new Filter();
            sortOrder.name = "sortOrder";
            sortOrder.value = this.sort.asc ? 'asc' : this.sort.asc === null ? null : 'desc';
            filters.push(sortOrder);
        }
        return filters;
    }

    launchBatch() {
        this.dispatcher.dispatch(new Action(ActionTypes.LAUNCH_BATCH, 'products')).toPromise()
            .then(() => {
            });
    }

}
