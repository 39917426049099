import {Component, OnInit} from '@angular/core';
import {Page} from "../../../@entities/Pageable/page";
import {DispatcherService} from "../../../services/dispatcher/dispatcher.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Action} from "../../../services/dispatcher/action";
import {ActionTypes} from "../../../services/dispatcher/action-types.enum";
import {Filter} from "../../../@entities/Pageable/filter";
import {IExecution} from "../../../@entities/iexecution";

@Component({
    selector: 'app-exectuion-screen',
    templateUrl: './execution-screen.component.html'
})
export class ExecutionScreenComponent implements OnInit {

    loading = false;
    defaultListSize = 25;
    executions: IExecution[];
    page: Page<IExecution> = new Page();
    sort: { field: string, asc: boolean };
    cols = [
        {header: 'Utilisateur', field: 'userNo'},
        {header: 'Identifiant de l\'éxécution', field: 'executionId'},
        {header: 'Type de batch', field: 'batchType'},
        {header: 'Date de modification', field: 'updatedDate'},
    ];

    criteriaFields = [
        {name: 'userNo', placeHolder: 'Numéro utilisateur', type: 'input', value: ''},
        {name: 'executionId', placeHolder: 'Identifiant de l\'éxécution', type: 'input', value: ''},
        {name: 'batchType', placeHolder: 'Type de batch', type: 'input', value: ''},
        {name: 'updatedDate', placeHolder: 'Date de modification', type: 'input', value: ''},
    ];
    private selectedExecution: IExecution;

    constructor(
        private dispatcher: DispatcherService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
    }

    getExecution() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_ALL_EXECUTION, this.page)).toPromise()
            .then((action) => {
                this.page = action.data;
                if (!this.page) {
                    this.selectedExecution = null;
                    this.executions = null;
                } else {
                    this.selectedExecution = this.page.content[0];
                    this.executions = this.page.content;
                }
            }).finally(() => {
            this.loading = false;
        });
    }

    public searchExecution() {
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
        this.getExecution();
    }

    public sortExecution(event: { field: string, asc: boolean }) {
        this.sort = {field: event.field, asc: event.asc};
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParamsList: Params = {};
        this.criteriaFields.forEach(criteria => {
            if (criteria.type === 'input') {
                if (criteria.value && criteria.value !== '') {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'select') {
                if (criteria.value && criteria.value.length !== 0) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'checkbox') {
                if (criteria.value !== null) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            }
        });
        if (this.sort && this.sort.field !== null) {
            queryParamsList.sort = this.sort.field;
            queryParamsList.sortOrder = this.sort.asc ? 'asc' : 'desc';
        }
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.pageNumber) {
                queryParamsList.pageNumber = param.pageNumber;
            }
        });
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }


    public loadExecutionPage(event: { rows: number, first: number }) {
        this.page.number = (event.first !== 0 && event.rows !== undefined) ? (event.first / event.rows) : 0;
        this.page.size = this.defaultListSize;
        this.getExecution();
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        this.criteriaFields.forEach(criteria => {
            if (criteria.value !== null) {
                const filter = new Filter();
                filter.name = criteria.name;
                filter.value = criteria.value;
                filters.push(filter);
            }
        });
        if (this.sort != null) {
            const sortField = new Filter();
            sortField.name = "sort";
            sortField.value = this.sort.field;
            filters.push(sortField);

            const sortOrder = new Filter();
            sortOrder.name = "sortOrder";
            sortOrder.value = this.sort.asc ? 'asc' : 'desc';
            filters.push(sortOrder);
        }
        return filters;
    }
}
