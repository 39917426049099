<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/execution'"
        [loading]="loading"
        [page]="page"
        [title]="'Execution'"
        [values]="executions"
        (searchItemEmitter)="searchExecution()"
        (sortEventEmitter)="sortExecution($event)"
        (loadItemPageEmitter)="loadExecutionPage($event)"
        [detailPage]="true"
        [displayImport]="false"
        [sortData]="true">
</app-list-tab-view>
