import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DetailDataViewComponent} from './detail-data-view/detail-data-view.component';
import {SharedModule} from "../../shared/shared.module";


@NgModule({
    declarations: [DetailDataViewComponent],
    exports: [
        DetailDataViewComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class DetailModule {
}
