<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/order-model'"
        [loading]="loading"
        [page]="page"
        [title]="'Modèles de commande'"
        [values]="orderModel"
        (searchItemEmitter)="searchOrderModel()"
        (sortEventEmitter)="sortOrderModel($event)"
        (loadItemPageEmitter)="loadOrderModelPage($event)"
        [displayImport]="true"
        [importName]="'order-model'"
        [detailPage]="false"
        (deleteItemEmitter)="deleteOrderModel($event)"
        [sortData]="true">
</app-list-tab-view>
