import {Component, OnInit} from '@angular/core';
import {IPricing} from '../../../@entities/ipricing';
import {StateService} from '../../../services/state/state.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-pricing-details',
    templateUrl: './pricing-details.component.html'
})
export class PricingDetailsComponent implements OnInit {
    loading: any;
    title: any;
    value: IPricing;

    constructor(
        private router: Router,
        private stateService: StateService
    ) {
    }

    ngOnInit(): void {
        this.value = this.stateService.data;
        if (!this.value) {
            this.router.navigateByUrl('/pricing');
        }
    }

    setTitle() {
        if (this.value) {
            this.title = this.value.pricingName;
        }
    }
}
