<div class="p-col-5 p-md-4 p-lg-2" *ngIf="value?.kit">
    <button pButton
            type="button"
            icon="pi pi-search-plus"
            label="Afficher les kits"
            class="ui-button-raised w-100"
            [disabled]="loading"
            (click)="displayKit()">
    </button>
</div>

<app-detail-tab-view
        [loading]="loading"
        [title]="title"
        [value]="value">
</app-detail-tab-view>


