import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StateService {

    data: any;
    data2: any;
    data3: any;

    constructor() {
    }
}
