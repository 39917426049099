<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/updated-orders'"
        [loading]="loading"
        [page]="page"
        [title]="'Mise à jour de commandes'"
        [values]="updatedOrder"
        (searchItemEmitter)="searchUpdatedOrder()"
        (sortEventEmitter)="sortUpdatedOrder($event)"
        (loadItemPageEmitter)="loadUpdatedOrderPage($event)"
        (launchBatchEmitter)="launchBatch()"
        [displayImport]="false"
        [displayLaunchImport]="true"
        [detailPage]="true"
        [sortData]="true">
</app-list-tab-view>
