import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpdatedOrderScreenComponent} from './updated-order-screen/updated-order-screen.component';
import {UpdatedOrderDetailsComponent} from './updated-order-details/updated-order-details.component';
import {SharedModule} from "../../shared/shared.module";
import {FeaturesModule} from "../../features/features.module";
import {ComponentsModule} from "../../components/components.module";


@NgModule({
    declarations: [UpdatedOrderScreenComponent, UpdatedOrderDetailsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class UpdatedOrderModule {
}
