<ng-template [ngIf]="userInfo">
    <div class="ml-auto">
        <div class="navbar-header">
            <button type="button" class="logged-user-button"
                    (click)="showCurrentUserMenu()"
                    title="{{ userInfo.profile.name }}">{{ userInfo.profile.family_name[0] }}{{ userInfo.profile.given_name[0] }}</button>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="userInfo">
    <p-sidebar [(visible)]="sideBarService.sideBarRightDisplay"
               class="sidebar-user-info"
               styleClass="ui-sidebar-md"
               position="right"
               [baseZIndex]="10000">
        <h4>Mon compte</h4>

        <hr>
        <div class="row">
            <div class="col-2">
                <div class="logged-user-button in-sidebar-info">
                    {{ userInfo.profile.family_name[0] }}{{ userInfo.profile.given_name[0] }}
                </div>
            </div>
            <div class="col-10">
                <h6 class="no-margin font-weight-bold">{{userInfo.profile.name}}</h6>
                <p>{{userInfo.userName}}</p>
                <p>Role [{{role}}]</p>
                <a [routerLink]="" (click)="disconnection()">Déconnexion</a>
            </div>
        </div>
    </p-sidebar>
</ng-template>
