import {Component} from '@angular/core';
import {MsAdalAngular6Service} from "microsoft-adal-angular6";
import {MenuSideService} from "../services/menu-side.service";
import {Role} from "src/app/@entities/role";
import {AuthenticationService} from "src/app/services/authentication/authentication.service";

@Component({
    selector: 'app-menu-side-right',
    templateUrl: './menu-side-right.component.html',
    styleUrls: ['./menu-side-right.component.scss']
})
export class MenuSideRightComponent {
    userInfo: adal.User;

    role: Role;

    constructor(
        private adalSvc: MsAdalAngular6Service,
        private authenticationService: AuthenticationService,
        private sideBarService: MenuSideService
    ) {
        this.userInfo = this.authenticationService.user;
        this.role = this.authenticationService.role;
    }

    showCurrentUserMenu() {
        this.sideBarService.displaySideBarRight();
    }

    disconnection() {
        this.adalSvc.logout();
    }

}
