<button pButton
        type="button"
        icon="pi pi-refresh"
        label="Refresh"
        class="ui-button-raised w-10"
        (click)="getExecutionDetail()"></button>

<app-detail-tab-view
        [loading]="loading"
        [title]="'Detail de l\'éxecution'"
        [value]="value">
</app-detail-tab-view>
