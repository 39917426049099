<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/customer'"
        [loading]="loading"
        [page]="page"
        [title]="'Clients'"
        [values]="customers"
        (searchItemEmitter)="searchCustomer()"
        (sortEventEmitter)="sortCustomer($event)"
        (loadItemPageEmitter)="loadCustomerPage($event)"
        (launchBatchEmitter)="launchBatch()"
        [displayImport]="false"
        [displayLaunchImport]="true"
        [detailPage]="true"
        [sortData]="true">
</app-list-tab-view>

