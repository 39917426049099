import {Injectable} from '@angular/core';
import {IAppConfig} from './app-config.model';
import {environment} from 'src/environments/environment';

@Injectable()
export class AppConfig {
    static settings: IAppConfig;

    constructor() {
    }

    load() {
        function handleError() {
            document.getElementById("loading").style.display = "none";
            document.getElementById("error").style.display = "block";
        }

        return new Promise<void>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', environment.apiUrl + environment.resourceUri.config);
            xhr.addEventListener('readystatechange', () => {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                    try {
                        AppConfig.settings = JSON.parse(xhr.responseText);
                        resolve();
                    } catch (e) {
                        handleError();
                        reject("Does not seems to be JSON !");
                    }
                } else if (xhr.readyState === XMLHttpRequest.DONE) {
                    handleError();
                    reject("Something went wrong...");
                }
            });
            xhr.send(null);
        });
    }
}

