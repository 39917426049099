<p-sidebar [(visible)]="sideBarService.sideBarLeftDisplay" [showCloseIcon]="false" position="left">
    <button class="burger side-bar-burger" (click)="sideBarService.hideSideBarLeft()">
        <i class="pi pi-bars" ></i>
    </button>
    <h4 class="side-bar-left">Vilmocom Orchestrator</h4>
    <div class="side-bar-title">
        <h1>{{title}}</h1>
    </div>
    <p-tieredMenu #menu 
        [model]="items" 
        styleClass="slide-menu">
    </p-tieredMenu>
</p-sidebar>
