import {Component, OnInit} from '@angular/core';
import {Page} from '../../../@entities/Pageable/page';
import {DispatcherService} from '../../../services/dispatcher/dispatcher.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Action} from '../../../services/dispatcher/action';
import {ActionTypes} from '../../../services/dispatcher/action-types.enum';
import {Filter} from '../../../@entities/Pageable/filter';
import {IUpdatedOrder} from '../../../@entities/iupdated-order';

@Component({
    selector: 'app-updated-order-screen',
    templateUrl: './updated-order-screen.component.html'
})
export class UpdatedOrderScreenComponent implements OnInit {

    loading = false;
    defaultListSize = 25;
    updatedOrder: IUpdatedOrder[];
    page: Page<IUpdatedOrder> = new Page();
    sort: { field: string, asc: boolean };
    cols = [
        {header: 'Référence de commande client', field: 'customerOrderReference'},
        {header: 'Numéro commande Vilmocom', field: 'vilmocomOrderNumber'},
        {header: 'Numéro client', field: 'customerNumber'},
        {header: 'Date de facturation', field: 'billedDate'},
        {header: 'Magasin d\'usine', field: 'shopFactory'},
        {header: 'Référence JDE', field: 'jdeReference'},
        {header: 'Statut de la commande', field: 'orderStatus'},
        {header: 'Type de commande', field: 'orderType'},
        {header: 'Montant facturé', field: 'billedAmount'},
        {header: 'Date de livraison', field: 'deliveryDate'},
        {header: 'Proforma', field: 'proforma'},
        {header: 'Source', field: 'source'},
        {header: 'Date de modification', field: 'updatedDate'}
    ];

    criteriaFields = [
        {name: 'customerOrderReference', placeHolder: 'Référence de commande client', type: 'input', value: ''},
        {name: 'vilmocomOrderNumber', placeHolder: 'Numéro commande Vilmocom', type: 'input', value: ''},
        {name: 'customerNumber', placeHolder: 'Numéro client', type: 'input', value: ''},
        {name: 'billedDate', placeHolder: 'Date de facturation', type: 'input', value: ''},
        {name: 'shopFactory', placeHolder: 'Magasin d\'usine', type: 'input', value: ''},
        {name: 'jdeReference', placeHolder: 'Référence JDE', type: 'input', value: ''},
        {name: 'orderStatus', placeHolder: 'Statut de la commande', type: 'input', value: ''},
        {name: 'orderType', placeHolder: 'Type de commande', type: 'input', value: ''},
        {name: 'billedAmount', placeHolder: 'Montant facturé', type: 'input', value: ''},
        {name: 'deliveryDate', placeHolder: 'Date de livraison', type: 'input', value: ''},
        {name: 'proforma', placeHolder: 'Proforma', type: 'checkbox', value: ''},
        {name: 'source', placeHolder: 'Source', type: 'input', value: ''},
        {name: 'updatedDate', placeHolder: 'Date de modification', type: 'input', value: ''}
    ];
    private selectedUpdatedOrder: IUpdatedOrder;

    constructor(
        private dispatcher: DispatcherService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
    }

    getUpdatedOrder() {
        this.loading = true;
        this.page.filter = this.buildFilters();
        this.dispatcher.dispatch(new Action(ActionTypes.GET_ALL_UPDATED_ORDER, this.page)).toPromise()
            .then((action) => {
                this.page = action.data;
                if (!this.page) {
                    this.selectedUpdatedOrder = null;
                    this.updatedOrder = null;
                } else {
                    this.selectedUpdatedOrder = this.page.content[0];
                    this.updatedOrder = this.page.content;
                }
            }).finally(() => {
            this.loading = false;
        });
    }

    public searchUpdatedOrder() {
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
        this.getUpdatedOrder();
    }

    public sortUpdatedOrder(event: { field: string, asc: boolean }) {
        this.sort = {field: event.field, asc: event.asc};
        if (!this.page) {
            this.page = new Page();
        }
        this.page.number = 0;
        this.page.size = this.defaultListSize;
        this.setQueryParams();
    }

    private setQueryParams() {
        const queryParamsList: Params = {};
        this.criteriaFields.forEach(criteria => {
            if (criteria.type === 'input') {
                if (criteria.value && criteria.value !== '') {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'select') {
                if (criteria.value && criteria.value.length !== 0) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            } else if (criteria.type === 'checkbox') {
                if (criteria.value !== null) {
                    queryParamsList[criteria.name] = criteria.value;
                }
            }
        });
        if (this.sort && this.sort.field !== null) {
            queryParamsList.sort = this.sort.field;
            queryParamsList.sortOrder = this.sort.asc ? 'asc' : 'desc';
        }
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.pageNumber) {
                queryParamsList.pageNumber = param.pageNumber;
            }
        });
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }

    public loadUpdatedOrderPage(event: { rows: number, first: number }) {
        this.page.number = (event.first !== 0 && event.rows !== undefined) ? (event.first / event.rows) : 0;
        this.page.size = this.defaultListSize;
        this.getUpdatedOrder();
    }

    private buildFilters(): Array<Filter> {
        const filters = new Array<Filter>();
        this.criteriaFields.forEach(criteria => {
            if (criteria.value !== null) {
                const filter = new Filter();
                filter.name = criteria.name;
                filter.value = criteria.value;
                filters.push(filter);
            }
        });
        if (this.sort != null) {
            const sortField = new Filter();
            sortField.name = "sort";
            sortField.value = this.sort.field;
            filters.push(sortField);

            const sortOrder = new Filter();
            sortOrder.name = "sortOrder";
            sortOrder.value = this.sort.asc ? 'asc' : 'desc';
            filters.push(sortOrder);
        }
        return filters;
    }

    launchBatch() {
        this.dispatcher.dispatch(new Action(ActionTypes.LAUNCH_BATCH, 'updated-orders')).toPromise()
            .then(() => {
            });
    }
}
