import {Component, Input, OnInit} from '@angular/core';
import {DispatcherService} from "../../services/dispatcher/dispatcher.service";
import {ActionTypes} from "../../services/dispatcher/action-types.enum";
import {MessageService} from "primeng/api";
import {Action} from "../../services/dispatcher/action";
import {ActivatedRoute} from "@angular/router";
import {IImport} from "../../@entities/iimport";

@Component({
    selector: 'app-import',
    templateUrl: './import.component.html'
})
export class ImportComponent implements OnInit {

    @Input() actionName: string;
    action: ActionTypes;
    loading = false;
    importStatus: IImport;
    displayModal: boolean;

    uploadedFiles: any[] = [];

    constructor(private activatedRoute: ActivatedRoute,
                private dispatcher: DispatcherService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
    }


    myUploader(event: any, fileUpload) {
        let isValidForImport = true;
        switch (this.actionName) {
            case 'contact':
                this.action = ActionTypes.IMPORT_CONTACT;
                break;
            case 'order-model':
                this.action = ActionTypes.IMPORT_ORDER_MODEL;
                break;
            case 'reference':
                this.action = ActionTypes.IMPORT_REFERENCE;
                break;
            default:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Message import',
                    detail: 'L\'import n\'est pas possible',
                    life: 5000,
                });
                isValidForImport = false;
                break;
        }
        if (isValidForImport && event.files.length === 1) {
            this.import(event.files[0], fileUpload);
        }
    }

    import(file, fileUpload) {

        this.loading = true;
        const formData = new FormData();
        formData.append('file', file);
        this.dispatcher.dispatch(new Action(this.action, formData)).subscribe(
            action => {
                if (action !== undefined && action.data !== undefined && fileUpload != null) {
                    fileUpload.clear();
                    file.executionId = action.data;
                    this.uploadedFiles.push(file);
                }
            },
            error => console.log(error),
            () => this.loading = false
        );
    }

    getStatus(file: any) {
        this.dispatcher.dispatch(new Action(ActionTypes.IMPORT_STATUS, file.executionId)).subscribe(
            action => {
                this.importStatus = action.data;
                this.displayModal = true;
            },
            error => console.log(error),
            () => {
            }
        );
    }
}
