import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductScreenComponent} from './product-screen/product-screen.component';
import {ProductDetailsComponent} from './product-details/product-details.component';
import {SharedModule} from "../../shared/shared.module";
import {FeaturesModule} from "../../features/features.module";
import {ComponentsModule} from "../../components/components.module";
import {KitScreenComponent} from './kit-screen/kit-screen.component';
import {ProductKitDetailsComponent} from './product-kit-detail/product-kit-details.component';


@NgModule({
    declarations: [ProductScreenComponent, ProductDetailsComponent, KitScreenComponent, ProductKitDetailsComponent],
    imports: [
        CommonModule,
        SharedModule,
        FeaturesModule,
        ComponentsModule
    ]
})
export class ProductModule {
}
