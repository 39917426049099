import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
    selector: 'app-lazy-table-criteria',
    templateUrl: './lazy-table-criteria.component.html',
    styleUrls: ['./lazy-table-criteria.component.scss']
})
export class LazyTableCriteriaComponent implements OnInit {

    columns: any [];

    @Input() set cols(value: any[]) {
        this.columns = value;
        let add = false;
        this.activatedRoute.queryParams.subscribe(param => {
            this.columns.forEach(element => {
                if (param[element.name]) {
                    if (param[element.name] === 'true') {
                        element.value = true;
                    } else if (param[element.name] === 'false') {
                        element.value = false;
                    } else if (param[element.name] === 'null') {
                        element.value = null;
                    } else {
                        element.value = param[element.name];
                    }
                    add = true;
                }
            });
        });
        if (add) {
            this.searchEvent.emit(this.columns);
        }
    }

    get cols(): any [] {
        return this.columns;
    }

    @Input() loading = false;
    @Output() searchEvent = new EventEmitter<any>();

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
    }

    search() {
        const queryParamsList: Params = {};
        this.activatedRoute.queryParams.subscribe(param => {
            this.columns.forEach(element => {
                if (param[element.name] && param[element.name] !== '') {
                    queryParamsList[element.name] = param[element.name];
                }
            });
        });
        queryParamsList.pageNumber = 0;
        this.router.navigate([],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            }).then(_ => this.searchEvent.emit(this.columns));
    }

    deleteFilters() {
        this.columns.forEach(element => {
            if (element.value === true || element.value === false || element.value === null) {
                element.value = null;
            } else {
                element.value = '';
            }
        });
        const queryParamsList: Params = {};
        this.activatedRoute.queryParams.subscribe(param => {
            if (param.sort) {
                queryParamsList.sort = param.sort;
                queryParamsList.sortOrder = param.sortOrder;
            }
        });
        this.search();
        this.router.navigate([],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList,
            });
    }

    changeSelect() {
        const queryParamsList: Params = {};
        this.activatedRoute.queryParams.subscribe(param => {
            this.columns.forEach(element => {
                if (param.pageNumber) {
                    queryParamsList.pageNumber = param.pageNumber;
                }
                if (param[element.field] && param[element.field] !== '') {
                    queryParamsList[element.field] = param[element.field];
                }
            });
            if (param.sort) {
                queryParamsList.sort = param.sort;
                queryParamsList.sortOrder = param.sortOrder;
            }
        });
        this.router.navigate([],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParamsList
            });
    }
}
