import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../../../services/state/state.service";
import {Page} from "../../../@entities/Pageable/page";
import {IKit} from "../../../@entities/ikit";

@Component({
    selector: 'app-product-kit-detail',
    templateUrl: './kit-screen.component.html'
})
export class KitScreenComponent implements OnInit {

    loading = false;
    defaultListSize = 25;
    kit: IKit[];
    page: Page<IKit> = new Page();
    cols = [
        {header: 'Identifiant article', field: 'productId'},
        {header: 'Quantité nécessaire', field: 'quantityRequired'},
    ];

    criteriaFields = [
        {name: 'kitId', placeHolder: 'Identifiant kit', type: 'input', value: ''},
        {name: 'productId', placeHolder: 'Identifiant article', type: 'input', value: ''},
    ];


    constructor(private router: Router,
                private stateService: StateService) {
        this.kit = this.stateService.data2;
        if (this.stateService.data3) {
            this.stateService.data = this.stateService.data3;
        } else {
            this.stateService.data3 = this.stateService.data;
        }
        if (!this.kit) {
            this.router.navigateByUrl('/product/detail');
        }
    }

    ngOnInit(): void {
    }

    redirection() {
        this.stateService.data2 = null;
        window.history.back();
    }
}
