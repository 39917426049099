import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {Role} from "../../@entities/role";

@Component({
    selector: 'app-home-screen',
    templateUrl: './home-screen.component.html'
})
export class HomeScreenComponent implements OnInit {

    adminItems: MenuItem[];
    importItems: MenuItem[];
    role: Role;
    isImport: boolean;
    isAdmin: boolean;

    constructor(private authenticationService: AuthenticationService) {
        this.role = this.authenticationService.role;
    }

    ngOnInit() {
        this.isAdmin = this.role === Role.ADMIN;
        this.isImport = this.role === Role.IMPORT || this.role === Role.ADMIN;
        this.adminItems = [
            {label: 'Articles', icon: 'pi pi-briefcase', routerLink: 'product'},
            {label: 'Clients', icon: 'pi pi-users', routerLink: 'customer'},
            {label: 'Commandes', icon: 'pi pi-file-o', routerLink: 'order'},
            {label: 'Mises à jour commande', icon: 'pi pi-copy', routerLink: 'updated-orders'},
            {label: 'Fonds de broche', icon: 'pi pi-tags', routerLink: 'spit-background'},
            {label: 'Historiques de vente', icon: 'pi pi-clock', routerLink: 'sales-history'},
            {label: 'Tarifications', icon: 'pi pi-money-bill', routerLink: 'pricing'},
            {label: 'Logins', icon: 'pi pi-key', routerLink: 'login'}
        ];

        this.importItems = [
            {label: 'Référencements', icon: 'pi pi-list', routerLink: 'reference'},
            {label: 'Contacts', icon: 'pi pi-id-card', routerLink: 'contact'},
            {label: 'Modèles de commande', icon: 'pi pi-sitemap', routerLink: 'order-model'},
            {label: 'Executions', icon: 'pi pi-cog', routerLink: 'execution'}
        ];
    }

}
