<app-list-tab-view
        [cols]="cols"
        [criteriaFields]="criteriaFields"
        [detailsBaseURL]="'/spit-background'"
        [loading]="loading"
        [page]="page"
        [title]="'Fonds de broche'"
        [values]="spitBackground"
        (searchItemEmitter)="searchSpitBackground()"
        (sortEventEmitter)="sortSpitBackground($event)"
        (loadItemPageEmitter)="loadSpitBackgroundPage($event)"
        (launchBatchEmitter)="launchBatch()"
        [displayImport]="false"
        [displayLaunchImport]="true"
        [detailPage]="true"
        [sortData]="true">
</app-list-tab-view>
