<p-toolbar>
    <div class="ui-toolbar-group-left" (click)="onDisplaySideMenu()">
        <div class="ui-g">
            <div class="ui-g-3">
                <button class="burger">
                    <i class="pi pi-bars" ></i>
                </button>
            </div>
            <div class="ui-g-9">
                <h4 class="menu-title">Vilmocom Orchestrator</h4>
            </div>
        </div>
    </div>
    <div class="ui-toolbar-group-right">
        <app-menu-side-right></app-menu-side-right>
    </div>
</p-toolbar>
