import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {IImport} from "../../../@entities/iimport";
import {StorageService} from "../../storage/storage.service";

@Injectable({
    providedIn: 'root'
})
export class ImportManagerService {
    api = environment.apiUrl + environment.resourceUri.import;
    batchTriggerApi = environment.apiUrl + environment.resourceUri.launchBatch;

    constructor(private storageService: StorageService) {
    }

    read(id?: number): Observable<IImport>;
    read(id?: any): Observable<IImport> {
        return this.storageService.read(this.api + '/' + id);
    }

    launchBatch(batchName?: string): Observable<string>;
    launchBatch(batchName?: any): Observable<string> {
        return this.storageService.read(this.batchTriggerApi + '/' + batchName);
    }
}
