import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../../../services/state/state.service";
import {IProduct} from "../../../@entities/iproduct";
import {Page} from "../../../@entities/Pageable/page";

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html'
})
export class ProductDetailsComponent implements OnInit {
    page: Page<IProduct> = new Page();
    loading: any;
    title: any;
    value: IProduct;

    constructor(
        private router: Router,
        private stateService: StateService,
    ) {
    }

    ngOnInit(): void {
        this.value = this.stateService.data;
        if (!this.value) {
            this.router.navigateByUrl('/product');
        }
    }

    setTitle() {
        if (this.value) {
            this.title = this.value.productId;
        }
    }

    displayKit() {
        this.stateService.data2 = this.value.kitProducts;
        this.router.navigateByUrl('/product/kit');
    }
}
